<template>
    

        <!-- Slider -->
        <div class="section mt-4 full">
            <div class="carousel-single splide">
                <div class="splide__track">
                    <ul class="splide__list">

                        <li class="splide__slide">
                            <div class="card card-with-icon">
                                <div class="card-body pt-3 pb-3 text-center">
                                    <div class="card-icon bg-success mb-2">
                                        <ion-icon name="link"></ion-icon>
                                    </div>
                                    <h3 class="card-titlde mb-1">Recomendar a un amig@</h3>
                                    <p>¡Invita a tus amigos y gana premios!</p>
                                    <div class="row">
                                        <div class="col">
                                            <a href="#" class="btn btn-secondary">
                                                <ion-icon name="copy-outline"></ion-icon>
                                                Invitar ahora
                                            </a>
                                            <p>Pendiente ventana para compartir</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="splide__slide">
                            <div class="card card-with-icon">
                                <div class="card-body pt-3 pb-3 text-center">
                                    <div class="card-icon mb-2">
                                        <ion-icon name="chatbox-ellipses"></ion-icon>
                                    </div>
                                    <h3 class="card-titlde mb-1">Únase a nuestro Chat</h3>
                                    <p>¡Hablemos del mercado y del estratega!</p>
                                    <div class="row">
                                        <div class="col">
                                            <a href="component-messages.html" class="btn btn-block btn-primary">
                                                Únete ahora
                                            </a>
                                        </div>
                                        <div class="col">
                                            <a href="component-messages.html" class="btn btn-block btn-secondary">
                                                Ver grupos
                                            </a>
                                        </div>
                                    </div>
                                    <p>Se usa para Discord</p>
                                </div>
                            </div>
                        </li>

                        <li class="splide__slide">
                            <div class="card card-with-icon">
                                <div class="card-body pt-3 pb-3 text-center">
                                    <div class="card-icon bg-secondary mb-2">
                                        <ion-icon name="share-social-outline"></ion-icon>
                                    </div>
                                    <h3 class="card-titlde mb-1">Síganos</h3>
                                    <p>¡Síguenos en las redes sociales!</p>
                                    <div class="row">
                                        <div class="col">
                                            <a href="#" class="btn btn-block btn-facebook">
                                                <ion-icon name="logo-facebook"></ion-icon>
                                                Facebook
                                            </a>
                                        </div>
                                        <div class="col">
                                            <a href="#" class="btn btn-block btn-twitter">
                                                <ion-icon name="logo-twitter"></ion-icon>
                                                Twitter
                                            </a>
                                        </div>
                                    </div>
                                    <p>Pendiente crear perfiles</p>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
        <!-- * Slider -->

</template>

<script>
export default {
    name: 'SecionPromo',
    created() {
    },
    mounted() {
        // this.$root.runBase()
        document.querySelectorAll('.carousel-single').forEach(carousel => new Splide(carousel, {
            perPage: 3,
            rewind: true,
            type: "loop",
            gap: 16,
            padding: 16,
            arrows: false,
            pagination: false,
            breakpoints: {
                768: {
                    perPage: 1
                },
                991: {
                    perPage: 2
                }
            }
        }).mount());
    },
    methods: {
    },
}
</script>