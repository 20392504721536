<template>

    <!-- App Header -->
    <div class="appHeader no-border transparent position-absolute">
        <div class="left">
            <router-link v-bind:to="{ name: 'app-me' }" class="headerButton">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </router-link>
        </div>
        <div class="pageTitle"></div>
        <div class="right">
        </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule">

        <div class="section mt-2 text-center">
            <h1>Ingrese el código SMS</h1>
            <h4>Ingrese el código de verificación por SMS de 4 dígitos</h4>
        </div>
        <div class="section mb-5 p-2">
            <form action="/me">
                <div class="form-group basic">
                    <input type="text" class="form-control verification-input" id="smscode" placeholder="••••"
                        maxlength="4">
                </div>

                <div class="form-button-group transparent">
                    <button type="submit" class="btn btn-primary btn-block btn-lg">Verificar</button>
                </div>

            </form>
        </div>

    </div>
    <!-- * App Capsule -->

</template>