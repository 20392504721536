<template>
    <!-- Send Action Sheet -->
    <div class="modal fade action-sheet" id="sendActionSheet" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Enviar Saldo</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <FormSend />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * Send Action Sheet-->
</template>

<script>
export default {
  name: 'ModalSendActionSheet',
}
</script>