<template>
    
    <!-- App Header -->
    <div class="appHeader">
        <div class="left">
            <router-link v-bind:to="{ name: 'app-me' }" class="headerButton">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </router-link>
        </div>
        <div class="pageTitle">Actualizar Direccion</div>
        <div class="right">
        </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule">

        <div class="section mt-2">
            <form @submit.prevent="submitForm">
                <!-- <div class="section-title">Direccion</div> -->
                <div class="card">
                    <div class="card-body">

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="select4">Pais</label>
                                <select class="form-control custom-select" @change="getStates" v-model="form.country" autofocus required>
                                    <option value="">Seleccione</option>
                                    <option v-for="(country, c) in options.contries" :key="country.iso3" v-bind:value="country.id">{{ country.name }}</option>
                                </select>
                            </div>
                            <!-- <div class="input-info">Actualmente solo estamos funcionando en colombia pero no te preocupes estamos a pasos de una gran expansion</div> -->
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="select4">Estado</label>
                                <select class="form-control custom-select" @change="getCities" v-model="form.state" required>
                                    <option value="">Seleccione el estado / departamento.</option>
                                    <option v-for="(cState, cs) in options.states" :key="cState.iso2" v-bind:value="cState.id">{{ cState.name }}</option>
                                </select>
                            </div>
                            <!-- <div class="input-info">Tipo de documento que se usara para la verificacion KYC</div> -->
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="select4">Ciudad</label>
                                <select class="form-control custom-select" v-model="form.city" required>
                                    <option value="">Seleccione la ciudad.</option>
                                    <option v-for="(cCity, cc) in options.cities" :key="cCity.wikiDataId" v-bind:value="cCity.id">{{ cCity.name }}</option>
                                </select>
                            </div>
                            <!-- <div class="input-info">Tipo de documento que se usara para la verificacion KYC</div> -->
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid3">Direccion completa</label>
                                <input type="text" class="form-control" placeholder="Tu direccion de facturacion" v-model="form.address" required>
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <div class="input-info">Ingresa la direccion de comprobacion</div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid3">Informacion adicional</label>
                                <input type="text" class="form-control" placeholder="Tu direccion de facturacion" v-model="form.address_extra">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <div class="input-info">Informacion extra de la direccion como puntos de referencia</div>
                        </div>
                        
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid3">Codigo postal</label>
                                <input type="text" class="form-control" id="userid3" placeholder="Tu codigo postal" v-model="form.postal_code" required>
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <!-- <div class="input-info">Numero del documento de identidad que sera usado para la verificacion KYC</div> -->
                        </div>

                    </div>
                </div>

                <!-- <div class="card">
                    <div class="card-body">

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid3">Primer Nombre</label>
                                <input type="text" class="form-control" placeholder="Tu Primer Nombre">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <div class="input-info">Escriba su primer nombre como aparece en el documento de identidad.</div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid3">Segundo u otros Nombre(s)</label>
                                <input type="text" class="form-control" placeholder="Tus nombres segundarios, si no tienes dejalo en blanco.">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <div class="input-info">Escriba su(s) nombre(s) segundarios como aparece(n) en el documento de identidad.</div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid3">Apellido(s)</label>
                                <input type="text" class="form-control" placeholder="Tu(s) apellido(s)">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <div class="input-info">Escriba su apellido como aparece en el documento de identidad.</div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid3">Fecha de nacimiento</label>
                                <input type="date" class="form-control" placeholder="Tu cumpleaños">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <div class="input-info">Necesitamos saber tu edad y si cumples con la edad minima.</div>
                        </div>
                    </div>
                </div> -->

                <br />
                
                <div class="form-button-group transparent">
                    <button type="submit" class="btn btn-primary btn-block btn-lg">Actualizar direccion</button>
                </div>
            </form>
        </div>
        <div class="section mt-2">
            <div class="card">
                <div class="card-body">
                    {{form}}
                </div>
            </div>
        </div>

        <div id="toast-11" class="toast-box toast-center">
            <div class="in">
                <ion-icon name="checkmark-circle" class="text-success"></ion-icon>
                <div class="text">
                    Perfil actualizado
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-light close-button">CERRAR</button>
        </div>
    </div>
    <!-- * App Capsule -->

</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import axios from 'axios';
export default {
    name: 'MeAddressEditView',
    data() {
        return {
            form: {
                address: '',
                address_extra: '',
                country: '',
                state: '',
                city: '',
                postal_code: '',
            },
            options: {
                contries: [],
                states: [],
                cities: [],
            },
        };
    },
    computed: {
        ...mapGetters({ 'currentInfo': 'StateUser' }),
    },
    mounted() {
        this.$root.runBase()
        this.getCountries()
        
        if (this.currentInfo) {
            this.form.address = this.currentInfo.address
            this.form.address_extra = this.currentInfo.address_extra
            this.form.country = this.currentInfo.country.id
            this.getStates()
            this.form.state = this.currentInfo.state.id
            this.getCities()
            this.form.city = this.currentInfo.city.id
            this.form.postal_code = this.currentInfo.postal_code
        }
    },
    methods: {
        submitForm() {
            let self = this
            // Verifica si los datos están disponibles antes de proceder
            if (
                this.form.address 
                && this.form.country 
                && this.form.state 
                && this.form.city
                && this.form.postal_code
            ) {
                console.log("Enviando");
                axios.post('account-info', self.form)
                .then(response => {
                    console.log('response', response)
                    if (response.status == 200) {
                        self.$root.toastbox('toast-11', 5000)
                        
                        // const toastEl = document.getElementById('toast-11');
                        // const toast = new Toast('toastEl');
                        // toast.show();
                        // location.reload()
                    }
                })
                .catch(e => {
                    console.log('e', e)
                })
            } else {
                console.error('Error: No se puede obtener los datos necesarios para la transacción.');
            }
        },
        getCountries() {
            let self = this
            axios.get('/world/countries')
            .then(response => {
                // console.log('response', response)
                if (response.status == 200) self.options.contries = response.data
            })
            .catch(e => {
                console.log(e)
            })
        },
        getStates() {
            let self = this
            self.options.states = []
            axios.get(`/world/states/${self.form.country}`, {
                params: {
                    order: 'name,asc',
                }
            })
            .then(response => {
                // console.log('response', response)
                if (response.status == 200) self.options.states = response.data
            })
            .catch(e => {
                console.log(e)
            })
        },
        getCities() {
            let self = this
            self.options.cities = []
            axios.get(`/world/cities/${self.form.state}`, {
                params: {
                    order: 'name,asc',
                }
            })
            .then(response => {
                // console.log('response', response)
                if (response.status == 200) self.options.cities = response.data
            })
            .catch(e => {
                console.log(e)
            })
        },
    },
}
</script>