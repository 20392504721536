<template>
    <AppHeader />
    <!-- App Capsule -->
    <div id="appCapsule">
        <SectionWalletCard />
        <SectionStats />
        <!-- <SectionPortfolio /> -->
        <!-- <SectionWatchlist /> -->
        <SectionTransactions />
        <!-- <SectionMyCards /> -->
        <SectionSendMoney />
        <!-- <SectionMonthlyBills />
        <SectionSavingGoals />
        <SectionLastestNews /> -->
         <br />
        <AppFooter />
    </div>
    <!-- * App Capsule -->
     <AppBottomMenu />
     <AppSidebar />
</template>

<script>
import SectionSendMoney from '@/components/SectionSendMoney.vue';
import ApexCharts from 'apexcharts'
export default {
    name: 'HomeView',
    created() {
    },
    mounted() {
        this.$root.runBase()
        
    },
    methods: {
    },
}
</script>