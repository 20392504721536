<template>
    
    <!-- App Header -->
    <div class="appHeader">
        <div class="left">
            <a href="/" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            Transacciones Activos
        </div>
        <div class="right">
            <!-- <a href="crypto-exchange.html" class="headerButton">
                <ion-icon name="add"></ion-icon>
            </a> -->
        </div>
    </div>
    <!-- * App Header -->


    <!-- App Capsule -->
    <div id="appCapsule">

        <!-- Hoy -->
        <div class="section mt-2">
            <div class="section-title">Today</div>
            <div class="card">
                <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
                    <!-- item -->
                    <li>
                        <a href="#" class="item">
                            <div class="icon-box bg-success">
                                <ion-icon name="arrow-up-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Bitcoin</strong>
                                    <div class="text-small text-secondary">Sell</div>
                                </div>
                                <div class="text-end">
                                    <strong>$855,24</strong>
                                    <div class="text-small">
                                        Today 11:38 AM
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <!-- * item -->
                </ul>
            </div>
        </div>
        <!-- * Today -->

        <!-- This Week -->
        <div class="section mt-2">
            <div class="section-title">Esta semana</div>
            <div class="card">
                <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
                    <!-- item -->
                    <li>
                        <a href="#" class="item">
                            <div class="icon-box bg-warning">
                                <ion-icon name="arrow-down-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Etherium</strong>
                                    <div class="text-small text-secondary">Buy</div>
                                </div>
                                <div class="text-end">
                                    <strong>$40,68</strong>
                                    <div class="text-small">
                                        March 8, 2021
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <!-- * item -->
                    <!-- item -->
                    <li>
                        <a href="#" class="item">
                            <div class="icon-box">
                                <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="image" class="imaged rounded w36">
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Bitcoin</strong>
                                    <div class="text-small text-secondary">Send</div>
                                </div>
                                <div class="text-end">
                                    <strong>$100,00</strong>
                                    <div class="text-small">
                                        March 4, 2021
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <!-- * item -->
                    <!-- item -->
                    <li>
                        <a href="#" class="item">
                            <div class="icon-box bg-secondary">
                                <ion-icon name="swap-vertical-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Tether</strong>
                                    <div class="text-small text-secondary">Trade</div>
                                </div>
                                <div class="text-end">
                                    <strong>$2.405,48</strong>
                                    <div class="text-small">
                                        February 24, 2021
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <!-- * item -->
                </ul>
            </div>
        </div>
        <!-- * This Week -->

        <!-- Agosto -->
        <div class="section mt-2">
            <div class="section-title">Agosto, 2024</div>
            <div class="card">
                <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
                    <!-- item -->
                    <li>
                        <a href="#" class="item">
                            <div class="icon-box">
                                <img src="@/assets/img/sample/avatar/avatar5.jpg" alt="image" class="imaged rounded w36">
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Etherium</strong>
                                    <div class="text-small text-secondary">Send</div>
                                </div>
                                <div class="text-end">
                                    <strong>$40,68</strong>
                                    <div class="text-small">
                                        Agosto 22, 2021
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <!-- * item -->
                    <!-- item -->
                    <li>
                        <a href="#" class="item">
                            <div class="icon-box">
                                <img src="@/assets/img/sample/avatar/avatar8.jpg" alt="image" class="imaged rounded w36">
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Bitcoin</strong>
                                    <div class="text-small text-secondary">Send</div>
                                </div>
                                <div class="text-end">
                                    <strong>$100,00</strong>
                                    <div class="text-small">
                                        Agosto 16, 2021
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <!-- * item -->
                    <!-- item -->
                    <li>
                        <a href="#" class="item">
                            <div class="icon-box bg-success">
                                <ion-icon name="arrow-up-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Bitcoin</strong>
                                    <div class="text-small text-secondary">Sell</div>
                                </div>
                                <div class="text-end">
                                    <strong>$855,24</strong>
                                    <div class="text-small">
                                        Agosto 15, 2021
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <!-- * item -->
                    <!-- item -->
                    <li>
                        <a href="#" class="item">
                            <div class="icon-box bg-success">
                                <ion-icon name="arrow-up-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Cardano</strong>
                                    <div class="text-small text-secondary">Sell</div>
                                </div>
                                <div class="text-end">
                                    <strong>$1.625,24</strong>
                                    <div class="text-small">
                                        Agosto 12, 2021
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <!-- * item -->
                    <!-- item -->
                    <li>
                        <a href="#" class="item">
                            <div class="icon-box bg-secondary">
                                <ion-icon name="swap-vertical-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Etherium</strong>
                                    <div class="text-small text-secondary">Trade</div>
                                </div>
                                <div class="text-end">
                                    <strong>$516,48</strong>
                                    <div class="text-small">
                                        Agosto 8, 2021
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <!-- * item -->
                </ul>
            </div>
        </div>
        <!-- * Agosto -->


        <div class="section mt-3 mb-3">
            <a href="#" class="btn btn-lg btn-block btn-primary">Cargar más</a>
        </div>


    </div>
    <!-- * App Capsule -->


</template>