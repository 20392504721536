<template>

    <!-- App Header -->
    <div class="appHeader">
        <div class="left">
            <a href="/" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            Noticias
        </div>
        <div class="right">
            <a href="#" class="headerButton toggle-searchbox">
                <ion-icon name="search-outline"></ion-icon>
            </a>
        </div>
    </div>
    <!-- * App Header -->

    <!-- Search Component -->
    <div id="search" class="appHeader">
        <form class="search-form">
            <div class="form-group searchbox">
                <input type="text" class="form-control" placeholder="Search...">
                <i class="input-icon icon ion-ios-search"></i>
                <a href="#" class="ms-1 close toggle-searchbox"><i class="icon ion-ios-close-circle"></i></a>
            </div>
        </form>
    </div>
    <!-- * Search Component -->

    <!-- App Capsule -->
    <div id="appCapsule">

        <div class="section tab-content mt-2 mb-2">

            <div class="row">
                <div class="col-6 mb-2">
                    <a href="/blog-post/ID">
                        <div class="blog-card">
                            <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w-100">
                            <div class="text">
                                <h4 class="title">What will be the value of bitcoin in the next...</h4>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-6 mb-2">
                    <a href="/blog-post/ID">
                        <div class="blog-card">
                            <img src="@/assets/img/sample/photo/2.jpg" alt="image" class="imaged w-100">
                            <div class="text">
                                <h4 class="title">Rules you need to know in business</h4>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-6 mb-2">
                    <a href="/blog-post/ID">
                        <div class="blog-card">
                            <img src="@/assets/img/sample/photo/3.jpg" alt="image" class="imaged w-100">
                            <div class="text">
                                <h4 class="title">10 easy ways to save your money</h4>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-6 mb-2">
                    <a href="/blog-post/ID">
                        <div class="blog-card">
                            <img src="@/assets/img/sample/photo/4.jpg" alt="image" class="imaged w-100">
                            <div class="text">
                                <h4 class="title">Follow the financial agenda with...</h4>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-6 mb-2">
                    <a href="/blog-post/ID">
                        <div class="blog-card">
                            <img src="@/assets/img/sample/photo/5.jpg" alt="image" class="imaged w-100">
                            <div class="text">
                                <h4 class="title">Does it make sense to invest in cryptocurrencies</h4>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-6 mb-2">
                    <a href="/blog-post/ID">
                        <div class="blog-card">
                            <img src="@/assets/img/sample/photo/6.jpg" alt="image" class="imaged w-100">
                            <div class="text">
                                <h4 class="title">What you need to withdraw money</h4>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div>
                <a href="#" class="btn btn-block btn-primary btn-lg">Load More</a>
            </div>

        </div>

    </div>
    <!-- * App Capsule -->


    <!-- App Bottom Menu -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Overview</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Pages</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Components</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>My Cards</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Settings</strong>
            </div>
        </a>
    </div>
    <!-- * App Bottom Menu -->

</template>