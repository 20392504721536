<template>

    <!-- App Header -->
    <div class="appHeader">
        <div class="left">
            <a href="/" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            Detalle de la transacción
        </div>
        <div class="right">
            <a href="#" class="headerButton" data-bs-toggle="modal" data-bs-target="#DialogBasic">
                <ion-icon name="trash-outline"></ion-icon>
            </a>
            <a href="#" class="headerButton" data-bs-toggle="modal" data-bs-target="#DialogBasic">
                <ion-icon name="alert"></ion-icon>
            </a>
        </div>
    </div>
    <!-- * App Header -->

    <!-- Dialog Basic -->
    <div class="modal fade dialogbox" id="DialogBasic" data-bs-backdrop="static" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Eliminar / Ocultar</h5>
                </div>
                <div class="modal-body">
                    Esto solo se elimina en tu cuenta, Estas seguro?
                </div>
                <div class="modal-footer">
                    <div class="btn-inline">
                        <a href="#" class="btn btn-text-secondary" data-bs-dismiss="modal">CANCELAR</a>
                        <a href="#" class="btn btn-text-primary" data-bs-dismiss="modal">ELIMINAR</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * Dialog Basic -->

    <!-- App Capsule -->
    <div id="appCapsule" class="full-height">

        <div class="section mt-2 mb-2">


            <div class="listed-detail mt-3">
                <div class="icon-wrapper">
                    <div class="iconbox">
                        <ion-icon name="arrow-forward-outline"></ion-icon>
                    </div>
                </div>
                <h3 class="text-center mt-2">Tipo de transaccion Deposito/Cobro/Retiro 3ros</h3>
            </div>

            <ul class="listview flush transparent simple-listview no-space mt-3">
                <li>
                    <strong>Estado</strong>
                    <span class="text-success">Completado / Exitoso</span>
                </li>
                <li>
                    <strong>Origen</strong>
                    <span>Jordi Santiago</span>
                </li>
                <li>
                    <strong>Para</strong>
                    <span>Jordi Santiago</span>
                </li>
                <li>
                    <strong>Red</strong>
                    <span>Envato Bank</span>
                </li>
                <li>
                    <strong>Categoría de transacción</strong>
                    <span>Deposito/Cobro/Retiro 3ros</span>
                </li>
                <li>
                    <strong>Comprobado</strong>
                    <span>SI</span>
                </li>
                <li>
                    <strong>Fecha</strong>
                    <span>Sep 25, 2024 10:45 AM</span>
                </li>
                <li>
                    <strong>Cantidad</strong>
                    <h3 class="m-0">24 000,00 COP</h3>
                </li>
            </ul>


        </div>

    </div>
    <!-- * App Capsule -->

</template>