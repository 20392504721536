<template>
    <!-- App Header -->
    <div class="appHeader">
        <div class="left">
            <router-link v-bind:to="{ name: 'app-home' }" class="headerButton">
                <div class="in">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </div>
            </router-link>
        </div>
        <div class="pageTitle">
            Notificaciones
        </div>
        <div class="right">
            <a href="#" class="headerButton" @click="testModal">
                <ion-icon name="notifications-off-outline"></ion-icon>
            </a>
        </div>
    </div>
    <!-- * App Header -->

    <!-- toast bottom iconed -->
    <div id="toast-example-1" class="toast-box toast-bottom bg-primary">
        <div class="in">
            <ion-icon name="notifications-off-outline"></ion-icon>
            <div class="text">
                Los sonidos de notificación han sido silenciados
            </div>
        </div>
        <button type="button" class="btn btn-sm btn-text-light close-button">OK</button>
    </div>
    <!-- * toast bottom iconed -->

    <!-- App Capsule -->
    <div id="appCapsule">

        <div class="section full">

            <ul class="listview image-listview flush">
                <li class="active">
                    <a href="/notification-detail/xxxx" class="item">
                        <div class="icon-box bg-primary">
                            <ion-icon name="arrow-down-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>
                                <div class="mb-05"><strong>Pago recibido</strong></div>
                                <div class="text-small mb-05">John te envió $50</div>
                                <div class="text-xsmall">5/3/2020 10:30 AM</div>
                            </div>
                            <span class="badge badge-primary badge-empty"></span>
                        </div>
                    </a>
                </li>
                <li class="active">
                    <a href="/notification-detail/xxxx" class="item">
                        <div class="icon-box bg-success">
                            <ion-icon name="arrow-forward-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>
                                <div class="mb-05"><strong>Se ha enviado el dinero</strong></div>
                                <div class="text-small mb-05">El dinero que enviaste a John fue enviado exitosamente.</div>
                                <div class="text-xsmall">5/3/2020 10:30 AM</div>
                            </div>
                            <span class="badge badge-primary">3</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="/notification-detail/xxxx" class="item">
                        <div class="icon-box bg-danger">
                            <ion-icon name="key-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>
                                <div class="mb-05"><strong>Contraseña cambiada</strong></div>
                                <div class="text-small mb-05">Su contraseña ha sido cambiada</div>
                                <div class="text-xsmall">5/3/2020 10:30 AM</div>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="/notification-detail/xxxx" class="item">
                        <div class="icon-box bg-warning">
                            <ion-icon name="chatbubble-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>
                                <div class="mb-05"><strong>Nuevos mensajes</strong></div>
                                <div class="text-small mb-05">Tienes nuevos mensajes de John</div>
                                <div class="text-xsmall">5/3/2020 10:30 AM</div>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="/notification-detail/xxxx" class="item">
                        <div class="icon-box bg-primary">
                            <ion-icon name="arrow-down-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>
                                <div class="mb-05"><strong>Pago recibido</strong></div>
                                <div class="text-small mb-05">John te envió $50</div>
                                <div class="text-xsmall">5/3/2020 10:30 AM</div>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="/notification-detail/xxxx" class="item">
                        <div class="icon-box bg-success">
                            <ion-icon name="arrow-forward-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>
                                <div class="mb-05"><strong>Se ha enviado el dinero</strong></div>
                                <div class="text-small mb-05">El dinero que enviaste a John fue enviado exitosamente.</div>
                                <div class="text-xsmall">5/3/2020 10:30 AM</div>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="/notification-detail/xxxx" class="item">
                        <div class="icon-box bg-danger">
                            <ion-icon name="key-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>
                                <div class="mb-05"><strong>Contraseña cambiada</strong></div>
                                <div class="text-small mb-05">Su contraseña ha sido cambiada</div>
                                <div class="text-xsmall">5/3/2020 10:30 AM</div>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="/notification-detail/xxxx" class="item">
                        <div class="icon-box bg-warning">
                            <ion-icon name="chatbubble-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>
                                <div class="mb-05"><strong>Nuevos mensajes</strong></div>
                                <div class="text-small mb-05">Tienes nuevos mensajes de John</div>
                                <div class="text-xsmall">5/3/2020 10:30 AM</div>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>

        </div>

    </div>
    <!-- * App Capsule -->


</template>

<script>
import { Toast } from 'bootstrap';

export default {
    name: 'NotificationsView',
    mounted() {
        this.$root.runBase()
    },
    methods: {
        testModal() {
            console.log('testModal')
            const toastEl = document.getElementById('toast-example-1');
            const toast = new Toast(toastEl);
            toast.show();
            // toastbox('toast-example-1', 3000)
        },
    },
}
</script>