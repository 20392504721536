<template>
    <!-- Transactions -->
    <div class="section mt-4">
        <div class="section-heading">
            <h2 class="title">Transacciones Activos (Pendte definir iconos y tipos de transacciones)</h2>
            <a href="/crypto/transactions" class="link">Ver todo</a>
        </div>
        <div class="card">
            <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
                <li v-for="(record, i) in records">
                    <a v-bind:href="'/crypto/transaction-detail/' + record.id" class="item">
                        <div class="icon-box bg-secondary">
                            <ion-icon v-bind:name="record.coin.icon"></ion-icon>
                        </div>
                        <div class="in">
                            <div>
                                <strong>{{ record.coin.name }}</strong>
                                <div class="text-small text-secondary">{{ record.type }}</div>
                            </div>
                            <div class="text-end">
                                <strong>{{ parseFloat(record.amount).toFixed(record.coin.decimals_show) }}</strong>
                                <div class="text-small">
                                    {{ record.date }}
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
                <!-- <li>
                    <a href="#" class="item">
                        <div class="icon-box bg-success">
                            <ion-icon name="arrow-up-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>
                                <strong>Bitcoin</strong>
                                <div class="text-small text-secondary">Sell</div>
                            </div>
                            <div class="text-end">
                                <strong>$855,24</strong>
                                <div class="text-small">
                                    Today 11:38 AM
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="#" class="item">
                        <div class="icon-box bg-warning">
                            <ion-icon name="arrow-down-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>
                                <strong>Etherium</strong>
                                <div class="text-small text-secondary">Buy</div>
                            </div>
                            <div class="text-end">
                                <strong>$40,68</strong>
                                <div class="text-small">
                                    March 8, 2021
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="#" class="item">
                        <div class="icon-box">
                            <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="image" class="imaged rounded w36">
                        </div>
                        <div class="in">
                            <div>
                                <strong>Bitcoin</strong>
                                <div class="text-small text-secondary">Send</div>
                            </div>
                            <div class="text-end">
                                <strong>$100,00</strong>
                                <div class="text-small">
                                    March 4, 2021
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="#" class="item">
                        <div class="icon-box bg-secondary">
                            <ion-icon name="swap-vertical-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>
                                <strong>Tether</strong>
                                <div class="text-small text-secondary">Trade</div>
                            </div>
                            <div class="text-end">
                                <strong>$2.405,48</strong>
                                <div class="text-small">
                                    February 24, 2021
                                </div>
                            </div>
                        </div>
                    </a>
                </li> -->
            </ul>
        </div>
    </div>
    <!-- Transactions -->
</template>

<script>
export default {
    name: 'Sectiontransacions',
    data() {
        return {
            records: [
                {
                    id: 1,
                    coin: {
                        id: 1,
                        name: 'Deposio en Bitcoin',
                        decimals: 8,
                        decimals_show: 2,
                        icon: 'logo-bitcoin'
                    },
                    type: 'Deposito/Cobro/Retiro 3ros',
                    amount: 855.24,
                    date: '2024-09-09',
                },
                {
                    id: 2,
                    coin: {
                        id: 2,
                        name: 'Ethereum / COPS',
                        decimals: 18,
                        decimals_show: 2,
                        icon: 'swap-horizontal-outline'
                    },
                    type: 'Deposito/Cobro/Retiro 3ros',
                    amount: 40.68,
                    date: '2024-09-09',
                },
                {
                    id: 3,
                    coin: {
                        id: 1,
                        name: 'Bitcoin',
                        decimals: 8,
                        decimals_show: 2,
                        icon: 'logo-bitcoin'
                    },
                    type: 'Deposito/Cobro/Retiro 3ros',
                    amount: 100.00,
                    date: '2024-09-09',
                },
                {
                    id: 4,
                    coin: {
                        id: 1,
                        name: 'Tether',
                        decimals: 8,
                        decimals_show: 2,
                        icon: 'logo-usd'
                    },
                    type: 'Deposito/Cobro/Retiro 3ros',
                    amount: 100.00,
                    date: '2024-09-09',
                },
            ],
        };
    },
}
</script>