<template>
    <div class="appHeader">
        <div class="left">
            <router-link :to="{ name: 'app-home' }" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </router-link>
        </div>
        <div class="pageTitle">
            Paginas
        </div>
        <div class="right">
        </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule">

        <div class="listview-title mt-1">Paginas</div>
        <ul class="listview image-listview inset mb-2">
            <li>
                <a href="/" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Vista general
                    </div>
                </a>
            </li>
            <li>
                <a href="/transactions" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Transacciones
                    </div>
                </a>
            </li>
            <li>
                <a href="/transaction-detail/ID" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Detalle de la transacción
                    </div>
                </a>
            </li>
            <li>
                <a href="/transaction-verification/ID" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                    Verificar transaccion
                    </div>
                </a>
            </li>
            <!-- <li>
                <a href="/my-cards" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Mis Tarjetas
                    </div>
                </a>
            </li> -->
            <!-- <li>
                <a href="/savings" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Ahorros
                    </div>
                </a>
            </li> -->
            <!-- <li>
                <a href="/bills" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Facturas
                    </div>
                </a>
            </li> -->
            <li>
                <a href="/notifications" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Notificaciones
                    </div>
                </a>
            </li>
            <li>
                <a href="/notification-detail/ID" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Detalles de notificacion
                    </div>
                </a>
            </li>
            <li>
                <a href="/me" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Perfil del usuario
                    </div>
                </a>
            </li>
        </ul>

        <div class="listview-title">Crypto activos</div>
        <ul class="listview image-listview inset mb-2">
            <li>
                <a href="/crypto" class="item">
                    <div class="icon-box bg-info">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Vision general
                    </div>
                </a>
            </li>
            <li>
                <a href="/crypto/coin-detail/ID" class="item">
                    <div class="icon-box bg-info">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Detales de Moneda
                    </div>
                </a>
            </li>
            <li>
                <a href="/crypto/exchange" class="item">
                    <div class="icon-box bg-info">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Intercambio
                    </div>
                </a>
            </li>
            <li>
                <a href="/crypto/portfolio" class="item">
                    <div class="icon-box bg-info">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Portafolio
                    </div>
                </a>
            </li>
            <li>
                <a href="/crypto/transactions" class="item">
                    <div class="icon-box bg-info">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Transacciones
                    </div>
                </a>
            </li>
            <li>
                <a href="/crypto/transaction-detail/ID" class="item">
                    <div class="icon-box bg-info">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Detalle de Transaccion
                    </div>
                </a>
            </li>
        </ul>

        <div class="listview-title">Autenticación</div>
        <ul class="listview image-listview inset mb-2">
            <li>
                <a href="/login" class="item">
                    <div class="icon-box bg-danger">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Iniciar sesion
                    </div>
                </a>
            </li>
            <li>
                <a href="/register" class="item">
                    <div class="icon-box bg-danger">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Crear cuenta
                    </div>
                </a>
            </li>
            <li>
                <a href="/forgot-password" class="item">
                    <div class="icon-box bg-danger">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Recuperar contraseña
                    </div>
                </a>
            </li>
            <li>
                <a href="/sms-verification" class="item">
                    <div class="icon-box bg-danger">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Verificacion con SMS
                    </div>
                </a>
            </li>
            <li>
                <a href="/qr-code" class="item">
                    <div class="icon-box bg-danger">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Verificacion con codigo QR
                    </div>
                </a>
            </li>
        </ul>

        <div class="listview-title">Blog</div>
        <ul class="listview image-listview inset mb-2">
            <li>
                <a href="/blog" class="item">
                    <div class="icon-box bg-secondary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Blog
                    </div>
                </a>
            </li>
            <li>
                <a href="/blog-post/ID" class="item">
                    <div class="icon-box bg-secondary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Blog Post
                    </div>
                </a>
            </li>
        </ul>

        <div class="listview-title">Apariencia</div>
        <ul class="listview image-listview inset mb-2">
            <li>
                <div class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="moon-outline"></ion-icon>
                    </div>
                    <div class="in">
                        <div>Modo Oscuro</div>
                        <div class="form-check form-switch  ms-2">
                            <input class="form-check-input dark-mode-switch" type="checkbox" id="darkmodeSwitch">
                            <label class="form-check-label" for="darkmodeSwitch"></label>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <!-- <div class="listview-title">Components</div>
        <ul class="listview image-listview inset mb-2">
            <li>
                <a href="/components" class="item">
                    <div class="icon-box bg-dark">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Discover Components
                    </div>
                </a>
            </li>
        </ul> -->

        <div class="listview-title">Otros</div>
        <ul class="listview image-listview inset mb-3">
            <li>
                <a href="/about" class="item">
                    <div class="icon-box bg-success">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Acerca de la APP
                    </div>
                </a>
            </li>
            <li>
                <a href="/contact" class="item">
                    <div class="icon-box bg-success">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Contacto
                    </div>
                </a>
            </li>
            <li>
                <a href="/faq" class="item">
                    <div class="icon-box bg-success">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        FAQ
                    </div>
                </a>
            </li>
            <li>
                <a href="/404" class="item">
                    <div class="icon-box bg-success">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        404
                    </div>
                </a>
            </li>
            <li>
                <a href="/splash" class="item">
                    <div class="icon-box bg-success">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Verificar Touch ID
                    </div>
                </a>
            </li>
            <li>
                <a href="/splash-image" class="item">
                    <div class="icon-box bg-success">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Splash Image
                    </div>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            form: {
                username: '@Username'
            }
        };
    },
    mounted() {
        let self = this
        var pageBody = document.querySelector("body");
        var switchDarkMode = document.querySelectorAll(".dark-mode-switch");
        // Dark Mode
        var switchDarkMode = document.querySelectorAll(".dark-mode-switch");

        function switchDarkModeCheck(value) {
            switchDarkMode.forEach(function (el) {
                el.checked = value
            })
        }
        switchDarkMode.forEach(function (el) {
            el.addEventListener("click", function () {
                var darkmodeCheck = localStorage.getItem("FinappDarkmode");
                var bodyCheck = pageBody.classList.contains('dark-mode');
                if (darkmodeCheck === 1 || darkmodeCheck === "1" || bodyCheck) {
                    pageBody.classList.remove("dark-mode");
                    localStorage.setItem("FinappDarkmode", "0");
                    switchDarkModeCheck(false);
                }
                else {
                    pageBody.classList.add("dark-mode")
                    switchDarkModeCheck(true);
                    localStorage.setItem("FinappDarkmode", "1");
                }
            })
        })
    },
    methods: {
        submit() {
            let self = this
            if (this.form.username !== this.$root.StateUser.username) {
                console.log('submit...', this.form.username, this.$root.StateUser.username)
                if (confirm("Desea cambiar el usuario de acceso por " + this.form.username)) {
                    self.$root.UI('change-user', {
                        "username": self.form.username,
                    }, (response) => {
                        console.log('response', response)
                        location.reload()
                    })
                }
            }
        },
        notificationPushTest() {
            let self = this
            if (!("Notification" in window)) {
                // Check if the browser supports notifications
                alert("This browser does not support desktop notification");
            } else if (Notification.permission === "granted") {
                // Check whether notification permissions have already been granted;
                // if so, create a notification
                const notification = new Notification("Hi there!");
                // …
            } else if (Notification.permission !== "denied") {
                // We need to ask the user for permission
                Notification.requestPermission().then((permission) => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    const notification = new Notification("Hi there!");
                    // …
                }
                });
            }

            // At last, if the user has denied notifications, and you
            // want to be respectful there is no need to bother them anymore.
        },
    },
}
</script>