<template>
    <div class="appHeader bg-primary text-light">
        <div class="left">
            <a href="#" class="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                <ion-icon name="menu-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            {{ $root.site.name.regular }}
        </div>
        <div class="right">
            <router-link v-bind:to="{ name: 'app-notifications' }" class="headerButton">
                <ion-icon class="icon" name="notifications-outline"></ion-icon>
                <span class="badge badge-danger" v-if="$root.notifications.length > 0">{{ $root.notifications.length }}</span>
            </router-link>
            
            <router-link v-bind:to="{ name: 'app-me' }" class="headerButton">
                <!-- <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="image" class="imaged w32"> -->
                <ion-icon class="icon w32" name="person-circle-outline"></ion-icon>
                <!-- <span class="badge badge-danger">6</span> -->
            </router-link>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'AppHeader',
}
</script>  