
<template>
    <div class="appHeader">
        <div class="left">
            <router-link v-bind:to="{ name: 'app-me' }" class="headerButton">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </router-link>
        </div>
        <div class="pageTitle">Actualizar Datos de acceso</div>
        <div class="right">
        </div>
    </div>

    <div id="appCapsule">
        
        <div class="section mt-2">
            <form @submit.prevent="submitForm">
                <div class="section-title">Datos de acceso</div>
                <div class="card">
                    <div class="card-body">
                        
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid3">Usuario</label>
                                <input type="text" class="form-control" placeholder="Tu nombre publico y usuario para acceso" v-model="form.username">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <!-- <div class="input-info">Escriba su primer nombre como aparece en el documento de identidad.</div> -->
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid3">Correo electronico</label>
                                <input type="email" class="form-control" placeholder="Tu correo electronico" v-model="form.email">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <!-- <div class="input-info">Escriba su(s) nombre(s) segundarios como aparece(n) en el documento de identidad.</div> -->
                        </div>

                    </div>
                </div>
                <br />
                
                <div class="form-button-group transparent">
                    <button type="submit" class="btn btn-primary btn-block btn-lg">Actualizar Perfil</button>
                </div>
            </form>
        </div>
        
        
        <div id="toast-11" class="toast-box toast-center">
            <div class="in">
                <ion-icon name="checkmark-circle" class="text-success"></ion-icon>
                <div class="text">
                    Perfil actualizado
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-light close-button">CERRAR</button>
        </div>

    </div>
</template>

<script>
import { Toast } from 'bootstrap';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import axios from 'axios';

export default {
    name: 'MeProfiileEditView',
    data() {
        return {
            form: {
                username: '',
                email: '',
            },
        };
    },
    computed: {
        ...mapGetters({ 'currentInfo': 'StateUser' }),
    },
    mounted() {
        let self = this;
        this.$root.runBase()

        if (this.currentInfo) {
            this.form.username = this.currentInfo.username
            this.form.email = this.currentInfo.email
        }
    },
    methods: {
        submitForm() {
            let self = this
            // Verifica si los datos están disponibles antes de proceder
            if (
                this.form.username 
                && this.form.email 
            ) {
                console.log("Enviando");
                axios.post('account-info', self.form)
                .then(response => {
                    console.log('response', response)
                    if (response.status == 200) {
                        self.$root.toastbox('toast-11', 5000)
                    }
                })
                .catch(e => {
                    console.log('e', e)
                })
            } else {
                console.error('Error: No se puede obtener los datos necesarios para la transacción.');
            }
        },
    },
}
</script>