<template>
    <div class="section mt-4">
        <div class="section-heading">
            <h2 class="title">Activos Digitales</h2>
            <a href="/crypto/portfolio" class="link">Ver todo</a>
        </div>
        <div class="card">
            <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
                <li v-for="(record, i) in records">
                    <a class="item" v-bind:href="'/crypto/coin-detail/' + record.id">
                        <div class="icon-box text-success">
                            <ion-icon name="trending-up-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>
                                <strong>{{ record.name }}</strong>
                                <div class="text-small text-secondary">{{ parseFloat(record.amount).toFixed(record.decimal_show) }} {{ record.symbol }}</div>
                                <div class="text-small text-secondary">{{ parseFloat(record.amount).toFixed(18) }} {{ record.symbol }}</div>
                            </div>
                            <div class="text-end">
                                <strong>{{ record.amount }} (pendiente organizar con la moneda del usuario y hacer rate)</strong>
                                <div class="text-small">
                                    <span class="badge badge-success">
                                        <ion-icon name="arrow-up-outline"></ion-icon>
                                        Indicar si lo recargado es mas o menos dde lo actual para calcular perdidas
                                    </span>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SectionPortfolio',
    data() {
        return {
            records: [
                {
                    id: 1,
                    name: 'Peso colombiano Sombrero',
                    symbol: 'COPS',
                    amount: 0.0000000001,
                    decimal_show: 2,
                },
                {
                    id: 2,
                    name: 'Tether',
                    symbol: 'USDT',
                    amount: 350.12000247315,
                    decimal_show: 2,
                },
                {
                    id: 3,
                    name: 'Binance Dolar',
                    symbol: 'BUSD',
                    amount: 12.829361641,
                    decimal_show: 2,
                },
            ],
        };
    },
}
</script>