<template>
    
    <!-- App Header -->
    <div class="appHeader">
        <div class="left">
            <router-link v-bind:to="{ name: 'app-me' }" class="headerButton">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </router-link>
        </div>
        <div class="pageTitle">Actualizar Movil</div>
        <div class="right">
        </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule">

        <div class="section mt-2">
            <form @submit.prevent="submitForm">
                <!-- <div class="section-title">Direccion</div> -->
                <div class="card">
                    <div class="card-body">

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="select4">Pais</label>
                                <select class="form-control custom-select" @change="getStates" v-model="form.mobile_country" autofocus required>
                                    <option value="">Seleccione</option>
                                    <option v-for="(country, c) in options.contries" :key="country.iso3" v-bind:value="country.phonecode">{{ country.name }}</option>
                                </select>
                            </div>
                            <!-- <div class="input-info">Actualmente solo estamos funcionando en colombia pero no te preocupes estamos a pasos de una gran expansion</div> -->
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid3">Numero de telefono movil</label>
                                <input type="text" class="form-control" placeholder="Tu numero movil" v-model="form.mobile_number" required>
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <!-- <div class="input-info">Ingresa la direccion de comprobacion</div> -->
                        </div>

                    </div>
                </div>

                <br />
                
                <div class="form-button-group transparent">
                    <button type="submit" class="btn btn-primary btn-block btn-lg">Verificar movil</button>
                </div>
            </form>
        </div>
        <div class="section mt-2">
            <div class="card">
                <div class="card-body">
                    +{{ form.mobile_country }} {{ form.mobile_number }}
                </div>
            </div>
        </div>

        <div id="toast-11" class="toast-box toast-center">
            <div class="in">
                <ion-icon name="checkmark-circle" class="text-success"></ion-icon>
                <div class="text">
                    Perfil actualizado
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-light close-button">CERRAR</button>
        </div>
    </div>
    <!-- * App Capsule -->

</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import axios from 'axios';
export default {
    name: 'MeAddressEditView',
    data() {
        return {
            form: {
                mobile_country: '',
                mobile_number: '',
            },
            options: {
                contries: [],
            },
        };
    },
    computed: {
        ...mapGetters({ 'currentInfo': 'StateUser' }),
    },
    mounted() {
        this.$root.runBase()
        this.getCountries()
        
        if (this.currentInfo) {
            this.form.mobile_number = this.currentInfo.mobile_number
            this.form.mobile_country = this.currentInfo.mobile_country
        }
    },
    methods: {
        submitForm() {
            let self = this
            // Verifica si los datos están disponibles antes de proceder
            if (
                this.form.mobile_country 
                && this.form.mobile_number 
            ) {
                console.log("Enviando");
                axios.post('account-info', self.form)
                .then(response => {
                    console.log('response', response)
                    if (response.status == 200) {
                        self.$root.toastbox('toast-11', 5000)
                    }
                })
                .catch(e => {
                    console.log('e', e)
                })
            } else {
                console.error('Error: No se puede obtener los datos necesarios para la transacción.');
            }
        },
        getCountries() {
            let self = this
            axios.get('/world/countries')
            .then(response => {
                // console.log('response', response)
                if (response.status == 200) self.options.contries = response.data
            })
            .catch(e => {
                console.log(e)
            })
        },
    },
}
</script>