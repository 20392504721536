<template>
    
        <form action="/exchange-exec">

            <div class="section mt-4">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group basic p-0">
                            <div class="exchange-heading">
                                <label class="group-label" for="fromAmount">Desde</label>
                                <div class="exchange-wallet-info">
                                    Balance : <strong> 5.506,12 COP</strong>
                                </div>
                            </div>
                            <div class="exchange-group">
                                <div class="input-col">
                                    <input type="text" class="form-control form-control-lg pe-0 border-0"
                                        id="fromAmount" placeholder="0" value="380" maxlength="10">
                                </div>
                                <div class="select-col">
                                    <select class="form-select form-select-lg currency">
                                        <option value="COP" selected>COP</option>
                                        <option value="BTC">BTC</option>
                                        <option value="ETH">ETH</option>
                                        <option value="ADA">ADA</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div class="section">
                <div class="exchange-line">
                    <div class="exchange-icon">
                        <ion-icon name="swap-vertical-outline"></ion-icon>
                    </div>
                </div>
            </div>

            <div class="section">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group basic p-0">
                            <div class="exchange-heading">
                                <label class="group-label" for="toAmount">Para</label>
                                <div class="exchange-wallet-info">
                                    Balance : <strong> 0,504 BTC</strong>
                                </div>
                            </div>
                            <div class="exchange-group">
                                <div class="input-col">
                                    <input type="text" class="form-control form-control-lg pe-0 border-0" id="toAmount"
                                        placeholder="0" value="0,014" maxlength="10">
                                </div>
                                <div class="select-col">
                                    <select class="form-select form-select-lg currency">
                                        <option value="COP">COP</option>
                                        <option value="BTC" selected>BTC</option>
                                        <option value="ETH">ETH</option>
                                        <option value="ADA">ADA</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>


            <div class="section mt-2">
                <div class="row fontsize-caption">
                    <div class="col">
                        <b>1 BTC</b> = 39.019,09 COP
                    </div>
                    <div class="col text-end">
                        Commission rate : <b>2%</b>
                    </div>
                </div>
            </div>

            <div class="form-button-group transparent">
                <button type="submit" class="btn btn-primary btn-block btn-lg">
                    Continuar
                </button>
            </div>
        </form>
</template>