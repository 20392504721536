<template>

    <!-- App Header -->
    <div class="appHeader bg-primary text-light">
        <div class="left">
            <a href="/crypto/portfolio" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            Bitcoin (BTC)
        </div>
        <div class="right">
            <a href="app-notifications.html" class="headerButton" data-bs-toggle="modal"
                data-bs-target="#actionSheetShare">
                <ion-icon name="share-social-outline"></ion-icon>
            </a>
        </div>
    </div>
    <!-- * App Header -->


    <!-- Share Action Sheet -->
    <div class="modal fade action-sheet inset" id="actionSheetShare" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Compartir con</h5>
                </div>
                <div class="modal-body">
                    <ul class="action-button-list">
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                <span>
                                    <ion-icon name="logo-facebook"></ion-icon>
                                    Facebook
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                <span>
                                    <ion-icon name="logo-twitter"></ion-icon>
                                    Twitter
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                <span>
                                    <ion-icon name="logo-instagram"></ion-icon>
                                    Instagram
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                <span>
                                    <ion-icon name="logo-linkedin"></ion-icon>
                                    Linkedin
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- * Share Action Sheet -->

    <!-- App Capsule -->
    <div id="appCapsule">


        <!-- Coin Status -->
        <div class="section full gradientSection">
            <div class="in coin-head">
                <h1 class="total">$ 32,517.50</h1>
                <h4 class="caption">
                    <span class="iconbox text-success">
                        <ion-icon name="caret-up"></ion-icon>
                    </span>
                    $2,325.19 <strong>(+1.50%)</strong>
                </h4>
            </div>
        </div>
        <!-- * Coin Status -->


        <!-- Coin Chart -->
        <div class="section mb-2">
            <div class="card coin-chart">
                <div class="card-body pt-1">
                    <!-- tabs -->
                    <ul class="nav nav-tabs lined" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="tab" href="#tab-24h" role="tab">24 Horas</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#tab-1w" role="tab">1 Semana</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#tab-1m" role="tab">1 Mes</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#tab-1y" role="tab">1 Año</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#tab-all" role="tab">Todo</a>
                        </li>
                    </ul>
                    <!-- * tabs -->
                </div>
                <!-- tab content -->
                <div class="tab-content">

                    <div class="tab-pane fade show active" id="tab-24h" role="tabpanel">
                        <div class="chart-example-1"></div>
                    </div>

                    <div class="tab-pane fade" id="tab-1w" role="tabpanel">
                        <div class="chart-example-2"></div>
                    </div>
                    <div class="tab-pane fade" id="tab-1m" role="tabpanel">
                        <div class="chart-example-3"></div>
                    </div>
                    <div class="tab-pane fade" id="tab-1y" role="tabpanel">
                        <div class="chart-example-4"></div>
                    </div>
                    <div class="tab-pane fade" id="tab-all" role="tabpanel">
                        <div class="chart-example-5"></div>
                    </div>
                </div>
                <!-- * tab content -->
            </div>
        </div>
        <!-- Coin Chart -->

        <!-- Buttons -->
        <div class="section">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <a href="/exchange" class="btn btn-block btn-lg btn-success">COMPRAR</a>
                        </div>
                        <div class="col">
                            <a href="/exchange" class="btn btn-block btn-lg btn-secondary">VENDER</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Buttons -->

        <!-- Stats -->
        <div class="section mt-2 mb-4">
            <div class="card">
                <ul class="listview no-line transparent flush simple-listview">
                    <li>
                        <div class="text-muted">Cambio diario</div>
                        <strong class="text-success">+5.10%</strong>
                    </li>
                    <li>
                        <div class="text-muted">Precio alto</div>
                        <strong>$56,367.23</strong>
                    </li>
                    <li>
                        <div class="text-muted">Precio bajo</div>
                        <strong>$18,529.90</strong>
                    </li>
                    <li>
                        <div class="text-muted">Oferta del mercado</div>
                        <strong>BTC 14.62M</strong>
                    </li>
                    <li>
                        <div class="text-muted">Capitalización de mercado</div>
                        <strong>$526.48B</strong>
                    </li>
                </ul>
            </div>
            <div class="card mt-2">
                <div class="card-body">
                    <div class="row mb-05 fontsize-sub">
                        <div class="col text-success"><strong>72% Compra</strong></div>
                        <div class="col text-secondary text-end"><strong>26% Venta</strong></div>
                    </div>
                    <div class="progress">
                        <div class="progress-bar bg-success" role="progressbar" style="width: 72%" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="progress-bar bg-secondary" role="progressbar" style="width: 28%" aria-valuenow="28" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Stats -->

        <!-- News -->
        <div class="section full mt-4 mb-4">
            <div class="section-heading padding">
                <h2 class="title">Noticias sobre la moneda</h2>
                <a href="app-blog.html" class="link">Ver todo</a>
            </div>

            <!-- carousel multiple -->
            <div class="carousel-multiple splide">
                <div class="splide__track">
                    <ul class="splide__list">

                        <li class="splide__slide">
                            <a href="app-blog-post.html">
                                <div class="blog-card">
                                    <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w-100">
                                    <div class="text">
                                        <h4 class="title">What will be the value of bitcoin in the next...</h4>
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li class="splide__slide">
                            <a href="app-blog-post.html">
                                <div class="blog-card">
                                    <img src="@/assets/img/sample/photo/2.jpg" alt="image" class="imaged w-100">
                                    <div class="text">
                                        <h4 class="title">Rules you need to know in business</h4>
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li class="splide__slide">
                            <a href="app-blog-post.html">
                                <div class="blog-card">
                                    <img src="@/assets/img/sample/photo/3.jpg" alt="image" class="imaged w-100">
                                    <div class="text">
                                        <h4 class="title">10 easy ways to save your money</h4>
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li class="splide__slide">
                            <a href="app-blog-post.html">
                                <div class="blog-card">
                                    <img src="@/assets/img/sample/photo/4.jpg" alt="image" class="imaged w-100">
                                    <div class="text">
                                        <h4 class="title">Follow the financial agenda with...</h4>
                                    </div>
                                </div>
                            </a>
                        </li>

                    </ul>
                </div>
            </div>
            <!-- * carousel multiple -->

        </div>
        <!-- * News -->


        <div class="section inset mb-4 mt-4">
            <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion01">
                            ¿Qué es {Moneda}?
                        </button>
                    </h2>
                    <div id="accordion01" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at feugiat tellus. Sed quis
                            velit tellus.
                        </div>
                    </div>
                </div>
                <!-- <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion02">
                            How Bitcoin works?
                        </button>
                    </h2>
                    <div id="accordion02" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at feugiat tellus. Sed quis
                            velit tellus.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion03">
                            How can i buy/sell Bitcoin?
                        </button>
                    </h2>
                    <div id="accordion03" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at feugiat tellus. Sed quis
                            velit tellus.
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <AppFooter />

    </div>
    <!-- * App Capsule -->

</template>

<script>
import ApexCharts from 'apexcharts'
export default {
    name: 'CryptoCoinDetailView',
    created() {
    },
    mounted() {
        this.$root.runBase()

        document.querySelectorAll('.carousel-multiple').forEach(carousel => new Splide(carousel, {
                perPage: 4,
                rewind: true,
                type: "loop",
                gap: 16,
                padding: 16,
                arrows: false,
                pagination: false,
                breakpoints: {
                    768: {
                        perPage: 2
                    },
                    991: {
                        perPage: 3
                    }
                }
            }).mount());
        

        var chartExample1 = {
            series: [{
                data: [512, 405, 666, 1090, 1309, 1400, 1500, 700, 1600, 1400, 1600, 2000, 1100, 501, 3000, 1000, 2000]
            }],
            chart: {
                type: 'area',
                width: '100%',
                height: 140,
                sparkline: {
                    enabled: true
                }
            },
            stroke: {
                width: 2,
            },
            colors: ['#1DCC70'],
            tooltip: {
                enabled: false
            }
        };

        var chartExample2 = {
            series: [{
                data: [512, 405, 666, 1090, 1309, 1400, 1500, 700, 1700, 1600, 1400, 1600]
            }],
            chart: {
                type: 'area',
                width: '100%',
                height: 140,
                sparkline: {
                    enabled: true
                }
            },
            stroke: {
                width: 2,
            },
            colors: ['#1DCC70'],
            tooltip: {
                enabled: false
            }
        };

        var chartExample3 = {
            series: [{
                data: [512, 405, 666, 1090, 1309, 1400, 1500, 700, 405, 666, 1090, 1309, 1400, 1500]
            }],
            chart: {
                type: 'area',
                width: '100%',
                height: 140,
                sparkline: {
                    enabled: true
                }
            },
            stroke: {
                width: 2,
            },
            colors: ['#1DCC70'],
            tooltip: {
                enabled: false
            }
        };

        var chartExample4 = {
            series: [{
                data: [512, 405, 666, 1090, 1309, 1400, 1500, 700, 1000, 2000, 512, 405, 666, 1090, 1309, 1400, 1500]
            }],
            chart: {
                type: 'area',
                width: '100%',
                height: 140,
                sparkline: {
                    enabled: true
                }
            },
            stroke: {
                width: 2,
            },
            colors: ['#1DCC70'],
            tooltip: {
                enabled: false
            }
        };

        var chartExample5 = {
            series: [{
                data: [512, 405, 666, 1090, 1309, 1400, 1309, 1400, 1500]
            }],
            chart: {
                type: 'area',
                width: '100%',
                height: 140,
                sparkline: {
                    enabled: true
                }
            },
            stroke: {
                width: 2,
            },
            colors: ['#1DCC70'],
            tooltip: {
                enabled: false
            }
        };

        // document.addEventListener('DOMContentLoaded', function () {
        // })
        document.querySelectorAll('.chart-example-1').forEach(chart => new ApexCharts(chart, chartExample1).render());
        document.querySelectorAll('.chart-example-2').forEach(chart => new ApexCharts(chart, chartExample2).render());
        document.querySelectorAll('.chart-example-3').forEach(chart => new ApexCharts(chart, chartExample3).render());
        document.querySelectorAll('.chart-example-4').forEach(chart => new ApexCharts(chart, chartExample4).render());
        document.querySelectorAll('.chart-example-5').forEach(chart => new ApexCharts(chart, chartExample5).render());
    }
}
</script>