<template>
    <div class="section mt-4">
        <div class="section-heading">
            <h2 class="title">Metas de ahorro</h2>
            <a href="/savings" class="link">Ver todo</a>
        </div>
        <div class="goals">
            <!-- item -->
            <div class="item">
                <div class="in">
                    <div>
                        <h4>Consola de videojuegos</h4>
                        <p>Juegos</p>
                    </div>
                    <div class="price">$ 499</div>
                </div>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">85%</div>
                </div>
            </div>
            <!-- * item -->
            <!-- item -->
            <div class="item">
                <div class="in">
                    <div>
                        <h4>New House</h4>
                        <p>Living</p>
                    </div>
                    <div class="price">$ 100,000</div>
                </div>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" style="width: 55%;" aria-valuenow="55"
                        aria-valuemin="0" aria-valuemax="100">55%</div>
                </div>
            </div>
            <!-- * item -->
            <!-- item -->
            <div class="item">
                <div class="in">
                    <div>
                        <h4>Sport Car</h4>
                        <p>Lifestyle</p>
                    </div>
                    <div class="price">$ 42,500</div>
                </div>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" style="width: 15%;" aria-valuenow="15"
                        aria-valuemin="0" aria-valuemax="100">15%</div>
                </div>
            </div>
            <!-- * item -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'SectionSavingGoals',
}
</script>