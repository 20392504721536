<template>

    <!-- App Header -->
    <div class="appHeader">
        <div class="left">
            
            <router-link v-bind:to="{ name: 'app-me' }" class="headerButton">
                <div class="in">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </div>
            </router-link>
        </div>
        <div class="pageTitle">Verificacion de direccion</div>
        <div class="right">
        </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule">

        <div class="section mt-2">
            <div class="section-title">Verificacion de direccion</div>
            <div class="card">
                <div class="card-body">
                    <form>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="amount1">Pais</label>
                                        <input type="text" class="form-control" id="amount1" placeholder="Cargando datos..."
                                        v-bind:value="currentInfo.country.name">
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="amount1">Ciudad</label>
                                        <input type="text" class="form-control" id="amount1" placeholder="Cargando datos..." v-bind:value="currentInfo.city.name">
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid1">Direccion completa</label>
                                <input type="text" class="form-control" id="userid1" placeholder="Cargando datos..." v-bind:value="currentInfo.address + ' ' + currentInfo.address_extra">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <br />
            <!-- <div class="section-title">Parte Frontal</div> -->
            <div class="card">
                <div class="card-body">

                    <form>
                        <div class="custom-file-upload" id="fileUpload1">
                            <input type="file" id="fileuploadInput" accept=".png, .jpg, .jpeg">
                            <label for="fileuploadInput">
                                <span>
                                    <strong>
                                        <ion-icon name="arrow-up-circle-outline"></ion-icon>
                                        <i>Upload a Photo</i>
                                    </strong>
                                </span>
                            </label>
                        </div>

                    </form>

                </div>
            </div>
        </div>










        <br />
                
                <div class="form-button-group transparent">
                    <button type="submit" class="btn btn-primary btn-block btn-lg">Actualizar Perfil</button>
                </div>

    </div>
    <!-- * App Capsule -->

</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
    name: 'MeAddressVerifyView',
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({ 'currentInfo': 'StateUser' }),
    },
    mounted() {
        let self = this;
        this.$root.runBase()
        if (this.currentInfo) {
            // this.form.email = this.currentInfo.email
        }
    },
}
</script>

