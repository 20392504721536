<template>
    <form>
        <!-- <div class="form-group basic mb-2">
            <div class="input-wrapper">
                <label class="label" for="walletaddress">ID del usuario o Direccion de la bitera</label>
                <input type="email" class="form-control" id="walletaddress" placeholder="ID del usuario o Direccion de la bitera">
                <i class="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                </i>
            </div>
        </div> -->
        <!-- <div class="form-group basic">
            <div class="input-wrapper">
                <label class="label" for="sendAmount">ID del usuario o Direccion de la billetera</label>
                <div class="exchange-group small">
                    <div class="input-col">
                        <input type="email" class="form-control" id="walletaddress" placeholder="ID del usuario o Direccion de la bitera">
                        <i class="clear-input">
                            <ion-icon name="close-circle"></ion-icon>
                        </i>
                    </div>
                    <div class="select-col">
                        <button type="button" class="btn btn-icon btn-outline-primary me-1" style="">
                            <ion-icon name="qr-code-outline" role="img" class="md hydrated" aria-label="newspaper outline"></ion-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group basic">
            <div class="input-wrapper">
                <label class="label" for="sendAmount">Ingrese la cantidad (min/max pendiente)</label>
                <div class="exchange-group small">
                    <div class="input-col">
                        <input type="text" class="form-control form-control-lg pe-0"
                            id="sendAmount" placeholder="0" value="" maxlength="14">
                    </div>
                    <div class="select-col">
                        <select class="form-select form-select-lg currency">
                            <option value="BTC" selected>BTC</option>
                            <option value="ETH">ETH</option>
                            <option value="ADA">ADA</option>
                            <option value="USDT">USDT</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-2">
            <a href="/send-select-gateway" class="btn btn-primary btn-lg btn-block">Enviar</a>
        </div> -->
       
        <div class="section mt-4">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="form-group basic">
                                <label class="label" for="userid3">Cantidad a enviar</label>
                                <div class="exchange-group">
                                    <div class="input-col">
                                        <input type="number" class="form-control form-control-lg pe-0 border-0" v-model="form.amount"
                                            autofocus="true" id="fromAmount" placeholder="0" maxlength="10" style="text-align: right;">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group basic">
                                <label class="label" for="userid3">Moneda / Divisa de pago</label>
                                <div class="exchange-group">
                                    <div class="select-col">
                                        <select class="form-select form-select-lg currency" v-model="form.coin_index">
                                            <!-- <option value="" selected>...</option> -->
                                            <option v-for="(record, i) in records" v-bind:value="i">{{ record.name }} ({{ record.symbol }})</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="exchange-line">
                <div class="exchange-icon">
                    <ion-icon name="swap-vertical-outline"></ion-icon>
                </div>
            </div>
        </div>

        
        <div class="section">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group basic">
                                <label class="label" for="userid3">Moneda / Divisa final</label>
                                <div class="exchange-group">
                                    <div class="select-col">
                                        <select class="form-select form-select-lg currency" v-model="form.pair_index" @change="changeB">
                                            <!-- <option value="" selected>Seleccione...</option> -->
                                            <option v-for="(pair, j) in options_pairs" v-bind:value="j">{{ pair.to.name }} ({{ pair.to.symbol }})</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="form-group basic">
                                <label class="label" for="userid3">Cantidad conv</label>
                                <div class="exchange-group">
                                    <div class="input-col">
                                        <input type="number" class="form-control form-control-lg pe-0 border-0" id="toAmount"
                                            placeholder="0" v-bind:value="preconvert" maxlength="10">
                                    </div>
                                </div>
                                <!-- <div class="input-wrapper">
                                    <label class="label" for="userid3">Cantidad a depositar</label>
                                    <input type="text" class="form-control" placeholder="Tu Primer Nombre">
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <div class="input-info">Escriba su primer nombre como aparece en el documento de identidad.</div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section mt-1 mb-3">
            <div class="row fontsize-caption">
                <div class="col">
                    <b>1 {{ currentAsymbol }}</b> = {{ currentRate }} {{ currentBsymbol }}
                </div>
                <div class="col text-end">
                    Tasa de comisión: <b>2%</b>
                </div>
            </div>
        </div>

        <!-- <div class="form-button-group transparent">
            <button type="submit" class="btn btn-primary btn-block btn-lg">
                Continuar
            </button>
        </div> -->
        
        <div class="section mt-2">
            <div class="section-title">Pagar transaccion</div>
            <div class="card">
                <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
                    <!-- item -->
                    <li v-for="(gateway, k) in options_gateways">
                        <div class="item">
                            <div class="icon-box text-success">
                                <ion-icon name="trending-up-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>{{ gateway.name }}</strong>
                                    <div class="text-small text-secondary">{{ gateway.network_from.name }} / {{ gateway.network_to.name }}</div>
                                </div>
                                <div class="text-end">
                                    <strong>
                                        {{ preconvert - calculeFee(gateway.rate_type, gateway.rate, preconvert) }} / 
                                        {{ preconvert + calculeFee(gateway.rate_type, gateway.rate, preconvert) }}
                                    </strong>
                                    <div class="text-small">
                                        <span class="badge badge-success">
                                            <ion-icon name="logo-usd"></ion-icon>
                                            {{ gateway.rate }} {{ gateway.rate_type }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- * item -->
                </ul>
            </div>
        </div>
        <div class="section mt-2">
            <div class="section-title">Pagar desde la transaccion</div>
            <div class="card">
                <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
                    <!-- item -->
                    <li v-for="(gateway, k) in options_gateways">
                        <div class="item">
                            <div class="icon-box text-success">
                                <ion-icon name="trending-up-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>{{ gateway.name }}</strong>
                                    <div class="text-small text-secondary">{{ gateway.network_from.name }} / {{ gateway.network_to.name }}</div>
                                </div>
                                <div class="text-end">
                                    <strong>
                                        {{ form.amount - calculeFee(gateway.rate_type, gateway.rate, form.amount) }} / 
                                        {{ preconvert - calculeFee(gateway.rate_type, gateway.rate, form.amount) }}
                                    </strong>
                                    <div class="text-small">
                                        <span class="badge badge-success">
                                            <ion-icon name="logo-usd"></ion-icon>
                                            {{ gateway.rate }} {{ gateway.rate_type }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- * item -->
                </ul>
            </div>
        </div>

    </form>
</template>

<script>
import axios from 'axios';

export default {
    name: 'FormSend',
    data() {
        return {
            records: [],
            form: {
                amount: '',
                coin_index: '',
                pair_index: 0,
                gateway_index: '',
            },
        };
    },
    computed: {
        options_pairs() {
            if (this.form.coin_index !== '' && this.records[this.form.coin_index].pairs) return this.records[this.form.coin_index].pairs;
            return [];
        },
        options_gateways() {
            if (this.form.coin_index !== '' && this.form.pair_index !== '' && this.records[this.form.coin_index] && this.records[this.form.coin_index].pairs[this.form.pair_index] && this.records[this.form.coin_index].pairs[this.form.pair_index].gateways) return this.records[this.form.coin_index].pairs[this.form.pair_index].gateways;
            return [];
        },
        // currentA() {
        //     if (this.form.coin_index !== '' && this.records[this.form.coin_index]) return this.records[this.form.coin_index];
        //     return undefined;
        // },
        currentAsymbol() {
            if (this.form.coin_index !== '' && this.records[this.form.coin_index]) return this.records[this.form.coin_index].symbol;
            return 'NaN';
        },
        currentAdecimalsShow() {
            if (this.form.coin_index !== '' && this.records[this.form.coin_index]) return this.records[this.form.coin_index].decimals_show;
            return 0;
        },
        currentBsymbol() {
            // if (this.to.coin_index !== '' && this.records[this.to.coin_index]) return this.records[this.to.coin_index].symbol;
            if (this.form.coin_index !== '' && this.form.pair_index !== '' && this.records[this.form.coin_index].pairs[this.form.pair_index]) return this.records[this.form.coin_index].pairs[this.form.pair_index].to.symbol;
            return 'NaN';
        },
        currentBdecimalsShow() {
            if (this.form.coin_index !== '' && this.form.pair_index !== '' && this.records[this.form.coin_index].pairs[this.form.pair_index]) return this.records[this.form.coin_index].pairs[this.form.pair_index].to.decimals_show;
            return 0;
        },
        currentRate() {
            if (this.form.coin_index !== '' && this.form.pair_index !== '' && this.records[this.form.coin_index].pairs[this.form.pair_index]) return this.records[this.form.coin_index].pairs[this.form.pair_index].rate;
            return 0;
        },
        preconvert() {
            if (this.currentRate > 0) {
                return this.form.amount / this.currentRate;
            }
            return 0;
        },
    },
    mounted() {
        let self = this;
        this.$root.runBase()

        axios.get('coins/send', {})
        .then((response) => {
            if (response.status == 200) {
                console.log('response', response)
                self.records = response.data.coins || [];
                if (self.records.length > 0) self.form.coin_index = 0;
            }
        })
        .catch(e => {
            console.clear()
            console.log('Error:GET:coins/send', e)
            let message = e.response.data.message ?? "No identificado"
            console.log('error', 'url:coins/send:message:', message)
        })
    },
    methods: {
        submit() {
            let self = this
            console.log('submit', this.form)
            location.replace('/send-select-gateway')
            // console.log('creando recarga')
            // this.$root.UI('create-recharge', {
            //     amount: self.form.amount,
            //     gateway_id: self.form.gateway,
            // }, (response) => {
            //     let newRouter = 'recharge/' + btoa(self.$root.StateUser.id + ':' + response)
            //     console.log('newRouter', newRouter)
            //     // self.$router.push(newRouter) Se debe solucionar el bg negro del sidebar
            //     location.replace(newRouter)
            // })
        },
        calculeFee(type, rate, amount) {
            if (type == 'amount') {
                return rate;
            }
            else if (type == 'percentage') {
                return (amount  * rate) / 100;
            }
            return -1;
        },
        changeB() {
        },
    },
}
</script>