<template>

    <!-- App Header -->
    <div class="appHeader">
        <div class="left">
            <router-link v-bind:to="{ name: 'app-me' }" class="headerButton">
                <div class="in">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </div>
            </router-link>
        </div>
        <div class="pageTitle">Verificacion de perfil</div>
        <div class="right">
        </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule">

        <div class="section mt-2">
            <div class="section-title">Documento de identificacion</div>
            <div class="card">
                <div class="card-body">
                    <form>
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid1">Nombre completo</label>
                                <input type="text" class="form-control" placeholder="Cargando datos..." 
                                    v-bind:value="currentInfo.first_name + ' ' + currentInfo.second_name + ' ' + currentInfo.surname">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="amount1">Tipo de documento</label>
                                        <input type="text" class="form-control" placeholder="Cargando datos..." v-bind:value="currentInfo.document_type.name">
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="amount1">Numero de documento</label>
                                        <input type="text" class="form-control" id="amount1" placeholder="Cargando datos..." v-bind:value="currentInfo.document_number">
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="section-title">Parte Frontal</div>
                    <div class="card">
                        <div class="card-body">

                            <form>
                                <div class="custom-file-upload" id="fileUpload1">
                                    <input type="file" id="fileuploadInput" accept=".png, .jpg, .jpeg">
                                    <label for="fileuploadInput">
                                        <span>
                                            <strong>
                                                <ion-icon name="arrow-up-circle-outline"></ion-icon>
                                                <i>Upload a Photo</i>
                                            </strong>
                                        </span>
                                    </label>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="section-title">Parte Trasera</div>
                    <div class="card">
                        <div class="card-body">

                            <form>
                                <div class="custom-file-upload" id="fileUpload1">
                                    <input type="file" id="fileuploadInput" accept=".png, .jpg, .jpeg">
                                    <label for="fileuploadInput">
                                        <span>
                                            <strong>
                                                <ion-icon name="arrow-up-circle-outline"></ion-icon>
                                                <i>Upload a Photo</i>
                                            </strong>
                                        </span>
                                    </label>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <br />
                
        <div class="form-button-group transparent">
            <button type="submit" class="btn btn-primary btn-block btn-lg">Verificar Perfil</button>
        </div>

    </div>
    <!-- * App Capsule -->

</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import axios from 'axios';
export default {
    name: 'MeProfileVerifyView',
    data() {
        return {
            form: {
            },
            options: {
            },
        };
    },
    computed: {
        ...mapGetters({ 'currentInfo': 'StateUser' }),
    },
    mounted() {
        this.$root.runBase()
    },
}
</script>