<template>
    <div class="appHeader">
        <div class="left">
            <router-link v-bind:to="{ name: 'app-me' }" class="headerButton">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </router-link>
        </div>
        <div class="pageTitle">Actualizar Perfil</div>
        <div class="right">
        </div>
    </div>

    <div id="appCapsule">
        <div class="section mt-2">
            <form @submit.prevent="submitForm">
                <div class="section-title">Informacion Personal</div>
                <div class="card">
                    <div class="card-body">

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid3">Primer Nombre</label>
                                <input type="text" class="form-control" placeholder="Tu Primer Nombre" v-model="form.first_name">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <div class="input-info">Escriba su primer nombre como aparece en el documento de identidad.</div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid3">Segundo u otros Nombre(s)</label>
                                <input type="text" class="form-control" placeholder="Tus nombres segundarios, si no tienes dejalo en blanco." v-model="form.second_name">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <div class="input-info">Escriba su(s) nombre(s) segundarios como aparece(n) en el documento de identidad.</div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid3">Apellido(s)</label>
                                <input type="text" class="form-control" placeholder="Tu(s) apellido(s)" v-model="form.surname">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <div class="input-info">Escriba su apellido como aparece en el documento de identidad.</div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid3">Fecha de nacimiento</label>
                                <input type="date" class="form-control" placeholder="Tu cumpleaños" v-model="form.birthday">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <div class="input-info">Necesitamos saber tu edad y si cumples con la edad minima.</div>
                        </div>
                    </div>
                </div>


                <div class="section-title">Informacion Legal</div>
                <div class="card">
                    <div class="card-body">
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="select4">Tipo de persona</label>
                                <select class="form-control custom-select" v-model="form.type">
                                    <option value="">Seleccione...</option>
                                    <option value="1">P. Natural</option>
                                    <option value="2">P. Juridica</option>
                                </select>
                            </div>
                            <div class="input-info">La persona natural, es la persona que de manera directa responde, a título personal, por sus obligaciones a título personal. Mientras tanto, la persona jurídica responde a una sociedad que puede estar conformada por múltiples personas que ejerce derechos y contrae obligaciones</div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="select4">Tipo de documento</label>
                                <select class="form-control custom-select" v-model="form.document_type">
                                    <option value="">Seleccione...</option>
                                    <option v-bind:value="i_document.id" v-for="(i_document, z) in options.identification_documents">
                                        {{ i_document.name }} [{{ i_document.slug }}]
                                    </option>
                                </select>
                            </div>
                            <div class="input-info">Tipo de documento que se usara para la verificacion KYC</div>
                        </div>
                        
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid3">Numero del documento</label>
                                <input type="text" class="form-control" id="userid3" placeholder="Tu numero de documento" v-model="form.document_number">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <div class="input-info">Numero del documento de identidad que sera usado para la verificacion KYC</div>
                        </div>

                    </div>
                </div>
                <br />
                
                <div class="form-button-group transparent">
                    <button type="submit" class="btn btn-primary btn-block btn-lg">Actualizar Perfil</button>
                </div>
            </form>
        </div>
        
        
        <div id="toast-11" class="toast-box toast-center">
            <div class="in">
                <ion-icon name="checkmark-circle" class="text-success"></ion-icon>
                <div class="text">
                    Perfil actualizado
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-light close-button">CERRAR</button>
        </div>

    </div>
</template>

<script>
import { Toast } from 'bootstrap';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import axios from 'axios';

export default {
    name: 'MeProfiileEditView',
    data() {
        return {
            form: {
                first_name: '',
                second_name: '',
                surname: '',
                birthday: '',
                type: '',
                document_type: '',
            },
            options: {
                identification_documents: [],
            },
        };
    },
    computed: {
        // 'user.info'() {
        //     return this.StateUser;
        // },
        ...mapGetters({ 'currentInfo': 'StateUser' }),
    },
    mounted() {
        let self = this;
        this.$root.runBase()

        axios.get('identification_documents')
        .then(response => {
            if (response.status == 200) self.options.identification_documents = response.data
        })
        .catch(e => {
            console.log(e)
        })

        if (this.currentInfo) {
            this.form.first_name = this.currentInfo.first_name
            this.form.second_name = this.currentInfo.second_name
            this.form.surname = this.currentInfo.surname
            this.form.birthday = this.currentInfo.birthday
            this.form.type = this.currentInfo.type.id
            this.form.document_type = this.currentInfo.document_type.id
            this.form.document_number = this.currentInfo.document_number
        }
    },
    methods: {
        submitForm() {
            let self = this
            // Verifica si los datos están disponibles antes de proceder
            if (
                this.form.first_name 
                && this.form.second_name 
                && this.form.surname 
                && this.form.birthday
                && this.form.type
                && this.form.document_type
                && this.form.document_number
            ) {
                console.log("Enviando");
                axios.post('account-info', self.form)
                .then(response => {
                    console.log('response', response)
                    if (response.status == 200) {
                        self.$root.toastbox('toast-11', 5000)
                        
                        // const toastEl = document.getElementById('toast-11');
                        // const toast = new Toast('toastEl');
                        // toast.show();
                        // location.reload()
                    }
                })
                .catch(e => {
                    console.log('e', e)
                })
            } else {
                console.error('Error: No se puede obtener los datos necesarios para la transacción.');
            }
        },
    },
}
</script>