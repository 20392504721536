<template>

    <!-- App Header -->
    <div class="appHeader">
        <div class="left">
            <a href="/notifications" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            Detalle de notificación
        </div>
        <div class="right">
            <a href="#" class="headerButton" data-bs-toggle="modal" data-bs-target="#DialogBasic">
                <ion-icon name="trash-outline"></ion-icon>
            </a>
        </div>
    </div>
    <!-- * App Header -->

    <!-- Dialog Basic -->
    <div class="modal fade dialogbox" id="DialogBasic" data-bs-backdrop="static" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Eliminar notificación</h5>
                </div>
                <div class="modal-body">
                    ¿Está seguro?
                </div>
                <div class="modal-footer">
                    <div class="btn-inline">
                        <a href="#" class="btn btn-text-secondary" data-bs-dismiss="modal">CANCELAR</a>
                        <a href="#" class="btn btn-text-primary" data-bs-dismiss="modal">ELIMINAR</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * Dialog Basic -->

    <!-- App Capsule -->
    <div id="appCapsule" class="full-height">

        <div class="section">

            <div class="listed-detail mt-3">
                <div class="icon-wrapper">
                    <div class="iconbox">
                        <ion-icon name="arrow-down-outline"></ion-icon>
                    </div>
                </div>
                <h3 class="text-center mt-2">Pago recibido</h3>
            </div>

            <ul class="listview simple-listview no-space mt-3">
                <li>
                    <span>Desde</span>
                    <strong>John Doe</strong>
                </li>
                <li>
                    <span>Nombre del banco</span>
                    <strong>Envato Bank</strong>
                </li>
                <li>
                    <span>Fecha</span>
                    <strong>Sep 25, 2024 10:45 AM</strong>
                </li>
                <li>
                    <span>Cantidad</span>
                    <strong>$ 50</strong>
                </li>
            </ul>


        </div>

    </div>
    <!-- * App Capsule -->

</template>