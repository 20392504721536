<template>
    
        <!-- Wallet Card -->
        <div class="section wallet-card-section pt-1">
            <div class="wallet-card">
                <!-- Balance -->
                <div class="balance">
                    <div class="left">
                        <span class="title">Total Balance</span>
                        <h1 class="total">$ 2,562.50</h1>
                    </div>
                    <div class="right">
                        <router-link to="/deposit" class="button">
                            <ion-icon name="add-outline"></ion-icon>
                        </router-link>
                    </div>
                </div>
                <!-- * Balance -->
                <!-- Wallet Footer -->
                <div class="wallet-footer">
                    <div class="item">
                        <router-link to="/withdraw" class="button">
                            <div class="icon-wrapper bg-danger">
                                <ion-icon name="arrow-up-outline"></ion-icon>
                            </div>
                            <strong>Retirar</strong>
                        </router-link>
                    </div>
                    <div class="item">
                        <router-link to="/send" class="button">
                            <div class="icon-wrapper">
                                <ion-icon name="arrow-forward-outline"></ion-icon>
                            </div>
                            <strong>Enviar</strong>
                        </router-link>
                    </div>
                    <!-- <div class="item">
                        <router-link to="/my-cards" class="button">
                            <div class="icon-wrapper bg-success">
                                <ion-icon name="card-outline"></ion-icon>
                            </div>
                            <strong>Mis TC</strong>
                        </router-link>
                    </div> -->
                    <div class="item">
                        <router-link to="/crypto/exchange" class="button">
                            <div class="icon-wrapper bg-warning">
                                <ion-icon name="swap-vertical"></ion-icon>
                            </div>
                            <strong>Intercambio</strong>
                        </router-link>
                    </div>

                </div>
                <!-- * Wallet Footer -->
            </div>
        </div>
        <!-- Wallet Card -->
</template>