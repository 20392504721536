<template>

    <template v-if="!stepOneOk">
        <form @submit.prevent="submitForm">
            <div class="section mt-4">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="form-group basic">
                                    <label class="label" for="userid3">Cantidad a enviar</label>
                                    <div class="exchange-group">
                                        <div class="input-col">
                                            <input type="number" class="form-control form-control-lg pe-0 border-0" v-model="form.amount" autofocus="true" id="fromAmount" placeholder="0" maxlength="10" style="text-align: right;" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group basic">
                                    <label class="label" for="userid3">Moneda / Divisa de pago</label>
                                    <div class="exchange-group">
                                        <div class="select-col">
                                            <select class="form-select form-select-lg currency" v-model="form.coin_index">
                                                <option v-for="(record, i) in records" :value="i">{{ record.name }} ({{ record.symbol }})</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="section">
                <div class="exchange-line">
                    <div class="exchange-icon">
                        <ion-icon name="swap-vertical-outline"></ion-icon>
                    </div>
                </div>
            </div>
    
            <div class="section">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group basic">
                                    <label class="label" for="userid3">Moneda / Divisa final</label>
                                    <div class="exchange-group">
                                        <div class="select-col">
                                            <select class="form-select form-select-lg currency" v-model="form.pair_index" @change="changeB">
                                                <option v-for="(pair, j) in options_pairs" :value="j">{{ pair.to.name }} ({{ pair.to.symbol }})</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="form-group basic">
                                    <label class="label" for="userid3">Cantidad conversion</label>
                                    <div class="exchange-group">
                                        <div class="input-col">
                                            <input type="number" class="form-control form-control-lg pe-0 border-0" id="toAmount"
                                                placeholder="0" v-model="preconvert" maxlength="10" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="section mt-1 mb-3">
                <div class="row fontsize-caption">
                    <div class="col">
                        <b>1 {{ currentAsymbol }}</b> = {{ currentRate }} {{ currentBsymbol }}
                    </div>
                    <div class="col text-end">
                        <!-- Tasa de comisión: <b>2%</b> -->
                    </div>
                </div>
            </div>
    
            <div class="section mt-2" v-for="(gateway, k) in options_gateways" :key="'gateway' + k">
                <div class="section-title">
                    {{ gateway.name}} ({{ gateway.network_from.name }} / {{ gateway.network_to.name }})
                </div>
                <div class="card">
                    <div class="card-body p-0">
                        <div class="input-list">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" :key="'from:' + k" :id="'from:' + k"  :value="'from:' + k" v-model="form.gateway_index" required />
                                <label class="form-check-label" :for="'from:' + k">
                                    Pagar por Transaccion: {{ preconvert + calculeFee(gateway.rate_type, gateway.rate, preconvert) }} {{ from.record.symbol }} x {{ form.amount }} {{ to.record.symbol }} ({{ gateway.rate }} {{ gateway.rate_type }})
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" :key="'to:' + k" :id="'to:' + k"  :value="'to:' + k" v-model="form.gateway_index" required />
                                <label class="form-check-label" :for="'to:' + k">
                                    Pagar por Transaccion: {{ preconvert + calculeFee(gateway.rate_type, gateway.rate, preconvert) }} {{ from.record.symbol }} x {{ form.amount }} {{ to.record.symbol }} ({{ gateway.rate }} {{ gateway.rate_type }})
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="form-button-group transparent">
                <button type="submit" class="btn btn-primary btn-block btn-lg">
                    Continuar
                </button>
            </div>
        </form>
    </template>
    <template v-else>
        
        <div class="section">
            <div class="splash-page mt-5 mb-5">

                <div class="transfer-verification">
                    <div class="transfer-amount">
                        <span class="caption">Cantidad</span>
                        <h2>{{ form.amount }} {{ from.record.symbol }} x {{ preconvert }} {{ to.record.symbol }}</h2>
                    </div>
                    <div class="from-to-block mb-5">
                        <div class="item text-start">
                            <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="avatar" class="imaged w48">
                            <strong>Jonathan</strong>
                        </div>
                        <div class="item text-end">
                            <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="avatar" class="imaged w48">
                            <strong>Amanda</strong>
                        </div>
                        <div class="arrow"></div>
                    </div>
                </div>
                <h2 class="mb-2 mt-2">Verificar la transacción</h2>
                <p>
                    You are sending <strong class="text-primary">$ 50.00</strong> to Amanda. <br>Are you sure?
                </p>
            </div>
        </div>

        <div class="fixed-bar">
            <div class="row">
                <div class="col-6">
                    <a href="/" class="btn btn-lg btn-outline-secondary btn-block">Cancel</a>
                </div>
                <div class="col-6">
                    <a href="/transaction-detail" class="btn btn-lg btn-primary btn-block">Confirm</a>
                </div>
            </div>
        </div>

    </template>

    
    <div class="section mt-4">
                <div class="card">
                    <div class="card-body">
                        {{ form }}
                    </div>
                </div>
            </div>
    
            <div class="section mt-4">
                <div class="card">
                    <div class="card-body">
                        {{ from }}
                    </div>
                </div>
            </div>
            <div class="section mt-4">
                <div class="card">
                    <div class="card-body">
                        {{ to }}
                    </div>
                </div>
            </div>
            <div class="section mt-4">
                <div class="card">
                    <div class="card-body">
                        {{ pair }}
                    </div>
                </div>
            </div>
            <div class="section mt-4">
                <div class="card">
                    <div class="card-body">
                        {{ gatewayInfo }}
                    </div>
                </div>
            </div>

</template>

<script>
import axios from 'axios';

export default {
    name: 'FormSend',
    props: ['type'],
    data() {
        return {
            records: [],
            form: {
                amount: '',
                coin_index: '',
                pair_index: 0,
                gateway_index: '',
                user_verify: false,
            },
        };
    },
    computed: {
        stepOneOk() {
            if (
                this.pair.record &&
                this.pair.record.from &&
                this.pair.record.to &&
                this.gatewayInfo.record && 
                this.form.user_verify
            ) {
                return true;
            } else {
                console.error('Error: No se puede obtener los datos necesarios para la transacción.');
            }
            return false;
        },
        from() {
            let record = this.form.coin_index !== '' && this.form.coin_index > -1 ? this.records[this.form.coin_index] : null;
            return {
                index: this.form.coin_index,
                record,
            };
        },
        to() {
            let record = this.from.record && this.form.pair_index !== '' && this.form.pair_index > -1 ? this.from.record.pairs[this.form.pair_index].to : null;
            return {
                index: this.form.pair_index,
                record,
            };
        },
        pair() {
            let record = this.from.record && this.form.pair_index !== '' && this.form.pair_index > -1 ? this.from.record.pairs[this.form.pair_index] : null;
            return {
                index: this.form.pair_index,
                record,
            };
        },
        gatewayInfo() {
            let pair_index = this.form.gateway_index.split(":")[1] ?? -1
            let record = this.from.record && this.to.record && this.form.gateway_index !== '' && pair_index > -1 ? this.from.record.pairs[this.form.pair_index].gateways[pair_index] : null;
            return {
                index: pair_index,
                record,
            };
        },
        options_pairs() {
            return this.form.coin_index !== '' && this.records[this.form.coin_index]?.pairs || [];
        },
        options_gateways() {
            return this.form.coin_index !== '' && this.form.pair_index !== '' && this.records[this.form.coin_index]?.pairs[this.form.pair_index]?.gateways || [];
        },
        currentAsymbol() {
            return this.form.coin_index !== '' && this.records[this.form.coin_index] ? this.records[this.form.coin_index].symbol : 'NaN';
        },
        currentAdecimalsShow() {
            return this.form.coin_index !== '' && this.records[this.form.coin_index] ? this.records[this.form.coin_index].decimals_show : 0;
        },
        currentBsymbol() {
            return this.form.coin_index !== '' && this.form.pair_index !== '' && this.records[this.form.coin_index]?.pairs[this.form.pair_index]?.to.symbol || 'NaN';
        },
        currentBdecimalsShow() {
            return this.form.coin_index !== '' && this.form.pair_index !== '' && this.records[this.form.coin_index]?.pairs[this.form.pair_index]?.to.decimals_show || 0;
        },
        currentRate() {
            return this.form.coin_index !== '' && this.form.pair_index !== '' && this.records[this.form.coin_index]?.pairs[this.form.pair_index]?.rate || 0;
        },
        preconvert() {
            return this.currentRate > 0 ? this.form.amount / this.currentRate : 0;
        },
    },
    mounted() {
        this.$root.runBase();

        axios.get(`coins/${this.type}`)
            .then(response => {
                if (response.status === 200) {
                    this.records = response.data || [];
                    if (this.records.length > 0) this.form.coin_index = 0;
                }
            })
            .catch(e => {
                console.clear();
                console.log('Error:GET:coins/send', e);
                let message = e.response?.data?.message ?? "No identificado";
                console.log('error', 'url:coins/send:message:', message);
            });
    },
    methods: {
        submitForm() {
            // Verifica si los datos están disponibles antes de proceder
            if (
                this.pair.record &&
                this.pair.record.from &&
                this.pair.record.to &&
                this.gatewayInfo.record
            ) {
                let tx = {
                    type: this.type,
                    coin_from: this.pair.record.from.id,
                    coin_to: this.pair.record.to.id,
                    amount: this.form.amount,
                    gateway_id: this.gatewayInfo.record.id,
                    pay_tx: this.form.gateway_index.split(":")[0] ?? 'NAN',
                };

                console.log(tx);
                this.form.user_verify= true
            } else {
                console.error('Error: No se puede obtener los datos necesarios para la transacción.');
                // Puedes agregar aquí un mensaje de error para el usuario si lo deseas
            }
        },
        calculeFee(type, rate, amount) {
            if (type === 'amount') {
                return rate;
            } else if (type === 'percentage') {
                return (amount * rate) / 100;
            }
            return -1;
        },
        changeB() {
            // Implement the logic for when the currency pair changes if needed
        },
    },
};
</script>
