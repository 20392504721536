<template>

    <!-- App Header -->
    <div class="appHeader">
        <div class="left">
            <a href="/" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            Mis activos digiales
        </div>
        <div class="right">
            <a href="/exchange" class="headerButton">
                <ion-icon name="add"></ion-icon>
            </a>
        </div>
    </div>
    <!-- * App Header -->


    <!-- App Capsule -->
    <div id="appCapsule">

        <!-- Top Coins -->
        <div class="section mt-2">
            <div class="section-title">Las mas usadas</div>
            <div class="card">
                <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
                    <li v-for="(record, i) in records">
                        <a class="item" v-bind:href="'/crypto/coin-detail/' + record.id">
                            <div class="icon-box text-success">
                                <ion-icon name="trending-up-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>{{ record.name }}</strong>
                                    <div class="text-small text-secondary">{{ parseFloat(record.amount).toFixed(record.decimal_show) }} {{ record.symbol }}</div>
                                    <div class="text-small text-secondary">{{ parseFloat(record.amount).toFixed(18) }} {{ record.symbol }}</div>
                                </div>
                                <div class="text-end">
                                    <strong>{{ record.amount }} (pendiente organizar con la moneda del usuario y hacer rate)</strong>
                                    <div class="text-small">
                                        <span class="badge badge-success">
                                            <ion-icon name="arrow-up-outline"></ion-icon>
                                            Indicar si lo recargado es mas o menos dde lo actual para calcular perdidas
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- Top Coins -->

        <!-- Most Active -->
        <div class="section mt-2">
            <div class="section-title">Most Active</div>
            <div class="card">
                <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
                    <!-- item -->
                    <li>
                        <div class="item">
                            <div class="icon-box text-success">
                                <ion-icon name="trending-up-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Bitcoin</strong>
                                    <div class="text-small text-secondary">0.053201 BTC</div>
                                </div>
                                <div class="text-end">
                                    <strong>$5,503.30</strong>
                                    <div class="text-small">
                                        <span class="badge badge-success">
                                            <ion-icon name="arrow-up-outline"></ion-icon>
                                            2.59%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- * item -->
                    <!-- item -->
                    <li>
                        <div class="item">
                            <div class="icon-box text-success">
                                <ion-icon name="trending-up-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Ethereum</strong>
                                    <div class="text-small text-secondary">45.5469 ETH</div>
                                </div>
                                <div class="text-end">
                                    <strong>$1,246.47</strong>
                                    <div class="text-small">
                                        <span class="badge badge-success">
                                            <ion-icon name="arrow-up-outline"></ion-icon>
                                            2.59%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- * item -->
                    <!-- item -->
                    <li>
                        <div class="item">
                            <div class="icon-box text-success">
                                <ion-icon name="trending-up-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Litecoin</strong>
                                    <div class="text-small text-secondary">45.61 LTC</div>
                                </div>
                                <div class="text-end">
                                    <strong>$409.90</strong>
                                    <div class="text-small">
                                        <span class="badge badge-success">
                                            <ion-icon name="arrow-up-outline"></ion-icon>
                                            62.59%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- * item -->
                    <!-- item -->
                    <li>
                        <div class="item">
                            <div class="icon-box text-success">
                                <ion-icon name="trending-up-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>USD Coin</strong>
                                    <div class="text-small text-secondary">45.5469 USDC</div>
                                </div>
                                <div class="text-end">
                                    <strong>$45.54</strong>
                                    <div class="text-small">
                                        <span class="badge badge-success">
                                            <ion-icon name="arrow-up-outline"></ion-icon>
                                            2.59%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- * item -->
                </ul>
            </div>
        </div>
        <!-- Most Active -->

        <!-- Others -->
        <div class="section mt-2 mb-3">
            <div class="section-title">Others</div>
            <div class="card">
                <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
                    <!-- item -->
                    <li>
                        <div class="item">
                            <div class="icon-box text-success">
                                <ion-icon name="trending-up-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Dogecoin</strong>
                                    <div class="text-small text-secondary">34,525.50 DOGE</div>
                                </div>
                                <div class="text-end">
                                    <strong>$35,258.32</strong>
                                    <div class="text-small">
                                        <span class="badge badge-success">
                                            <ion-icon name="arrow-up-outline"></ion-icon>
                                            5.49%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- * item -->
                    <!-- item -->
                    <li>
                        <div class="item">
                            <div class="icon-box text-danger">
                                <ion-icon name="trending-down-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>TRON</strong>
                                    <div class="text-small text-secondary">452.23 TRX</div>
                                </div>
                                <div class="text-end">
                                    <strong>$512.37</strong>
                                    <div class="text-small">
                                        <span class="badge badge-danger">
                                            <ion-icon name="arrow-down-outline"></ion-icon>
                                            2.59%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- * item -->
                    <!-- item -->
                    <li>
                        <div class="item">
                            <div class="icon-box text-danger">
                                <ion-icon name="trending-down-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Cardano</strong>
                                    <div class="text-small text-secondary">251.48 ADA</div>
                                </div>
                                <div class="text-end">
                                    <strong>$316.50</strong>
                                    <div class="text-small">
                                        <span class="badge badge-danger">
                                            <ion-icon name="arrow-down-outline"></ion-icon>
                                            2.59%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- * item -->
                    <!-- item -->
                    <li>
                        <div class="item">
                            <div class="icon-box text-success">
                                <ion-icon name="trending-up-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Tether</strong>
                                    <div class="text-small text-secondary">45.5469 USDT</div>
                                </div>
                                <div class="text-end">
                                    <strong>$45.54</strong>
                                    <div class="text-small">
                                        <span class="badge badge-success">
                                            <ion-icon name="arrow-up-outline"></ion-icon>
                                            2.59%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- * item -->
                    <!-- item -->
                    <li>
                        <div class="item">
                            <div class="icon-box text-success">
                                <ion-icon name="trending-up-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Bitcoin</strong>
                                    <div class="text-small text-secondary">0.053201 BTC</div>
                                </div>
                                <div class="text-end">
                                    <strong>$5,503.30</strong>
                                    <div class="text-small">
                                        <span class="badge badge-success">
                                            <ion-icon name="arrow-up-outline"></ion-icon>
                                            2.59%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- * item -->
                    <!-- item -->
                    <li>
                        <div class="item">
                            <div class="icon-box text-success">
                                <ion-icon name="trending-up-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Ethereum</strong>
                                    <div class="text-small text-secondary">45.5469 ETH</div>
                                </div>
                                <div class="text-end">
                                    <strong>$1,246.47</strong>
                                    <div class="text-small">
                                        <span class="badge badge-success">
                                            <ion-icon name="arrow-up-outline"></ion-icon>
                                            2.59%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- * item -->
                </ul>
            </div>
        </div>
        <!-- Others -->


    </div>
    <!-- * App Capsule -->


</template>


<script>
export default {
    name: 'CryptoPortfolioView',
    data() {
        return {
            records: [
                {
                    id: 1,
                    name: 'Peso colombiano Sombrero',
                    symbol: 'COPS',
                    amount: 0.0000000001,
                    decimal_show: 2,
                },
                {
                    id: 2,
                    name: 'Tether',
                    symbol: 'USDT',
                    amount: 350.12000247315,
                    decimal_show: 2,
                },
                {
                    id: 3,
                    name: 'Binance Dolar',
                    symbol: 'BUSD',
                    amount: 12.829361641,
                    decimal_show: 2,
                },
            ],
        };
    },
}
</script>