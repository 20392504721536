<template>
    <div class="register">
        <div class="appHeader no-border transparent position-absolute">
            <div class="left">
                <!-- <a href="#" class="headerButton goBack">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a> -->
            </div>
            <div class="pageTitle"></div>
            <div class="right">
                <a href="/login" class="headerButton">
                    ¿Ya tienes cuenta?
                </a>
            </div>
        </div>
        
        <div id="appCapsule">
            <div class="section mt-2 text-center">
                <h1>Regístrate ahora</h1>
                <h4>Crea una cuenta</h4>
            </div>
            <div class="section mb-5 p-2">
                <form @submit.prevent="submit">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="email1">Correo electronico</label>
                                    <input type="email" class="form-control" id="email1" placeholder="tu correo" v-model="form.username" required />
                                    <i class="clear-input"><ion-icon name="close-circle"></ion-icon></i>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="password1">Contraseña</label>
                                    <input type="password" class="form-control" id="password1" autocomplete="off" placeholder="tu contraseña / clave de acceso" v-model="form.password" required />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>

                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="password2">Confirme la contraseña</label>
                                    <input type="password" class="form-control" id="password2" autocomplete="off" placeholder="Escriba de nuevo su contraseña" v-model="form.password2" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>

                            <div class="custom-control custom-checkbox mt-2 mb-1">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="customCheckb1">
                                    <label class="form-check-label" for="customCheckb1">
                                        Al crear una cuenta se aceptan <a href="#" data-bs-toggle="modal" data-bs-target="#termsModal">terminos & condiciones</a>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="form-button-group transparent">
                        <button type="submit" class="btn btn-primary btn-block btn-lg">Crear cuenta</button>
                    </div>
                </form>
                <p v-if="showError" id="error">{{ messageError }}</p>
            </div>
        </div>
        <!-- * App Capsule -->

        <!-- Terms Modal -->
        <div class="modal fade modalbox" id="termsModal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Términos y condiciones</h5>
                        <a href="#" data-bs-dismiss="modal">Cerrar</a>
                    </div>
                    <div class="modal-body">
                        <div class="container">
                            <h1>Términos y Condiciones de Uso de SombreroWallet</h1>
                            <p><strong>Última actualización:</strong> [Fecha]</p>
                            
                            <h2>1. Introducción</h2>
                            <p>Bienvenido a SombreroWallet ("nosotros", "nuestro" o "la empresa"). Estos Términos y Condiciones ("Términos") regulan el uso de nuestra aplicación de wallet de criptomonedas ("Wallet"). Al acceder o utilizar SombreroWallet, aceptas estos Términos en su totalidad. Si no estás de acuerdo con estos Términos, no utilices la Wallet.</p>
                            
                            <h2>2. Definiciones</h2>
                            <ul>
                                <li><strong>Wallet:</strong> La aplicación de software que permite a los usuarios almacenar, gestionar y transaccionar criptomonedas.</li>
                                <li><strong>Usuario:</strong> Cualquier persona que se registre y utilice SombreroWallet.</li>
                                <li><strong>Criptomonedas:</strong> Monedas digitales como Bitcoin, Ethereum, entre otras, que se pueden almacenar y transaccionar mediante la Wallet.</li>
                            </ul>
                            
                            <h2>3. Requisitos de Registro</h2>
                            <p>Para utilizar SombreroWallet, debes completar el proceso de registro y proporcionar información precisa y actualizada. El registro puede requerir:</p>
                            <ul>
                                <li>Datos personales: nombre completo, documento de identidad (CC, TI o CE), dirección y correo electrónico.</li>
                                <li>Verificación KYC (Know Your Customer): Para cumplir con las regulaciones y prevenir el fraude, puede que solicitemos documentos adicionales para verificar tu identidad.</li>
                            </ul>
                            
                            <h2>4. Responsabilidades del Usuario</h2>
                            <ul>
                                <li><strong>Seguridad:</strong> Eres responsable de mantener la confidencialidad de tus credenciales de acceso y de cualquier actividad que ocurra bajo tu cuenta. Debes notificar inmediatamente a SombreroWallet sobre cualquier uso no autorizado de tu cuenta.</li>
                                <li><strong>Uso Legal:</strong> Aceptas no utilizar SombreroWallet para actividades ilegales, incluyendo pero no limitándose al lavado de dinero, financiación del terrorismo o cualquier otra actividad prohibida por la ley colombiana o internacional.</li>
                            </ul>
                            
                            <h2>5. Uso de la Wallet</h2>
                            <ul>
                                <li><strong>Transacciones:</strong> Puedes enviar y recibir criptomonedas utilizando SombreroWallet. Las transacciones están sujetas a tarifas, que serán informadas previamente.</li>
                                <li><strong>Tarifas:</strong> SombreroWallet puede cobrar tarifas por transacciones, conversión de criptomonedas, o servicios adicionales. Las tarifas aplicables estarán disponibles en la sección de tarifas de nuestra Wallet.</li>
                            </ul>
                            
                            <h2>6. Responsabilidad y Limitaciones</h2>
                            <ul>
                                <li><strong>Exclusión de Responsabilidad:</strong> SombreroWallet se proporciona "tal cual" y "según disponibilidad". No garantizamos la disponibilidad continua o sin errores de la Wallet, ni asumimos responsabilidad por cualquier pérdida o daño derivado del uso de la Wallet.</li>
                                <li><strong>Limitación de Responsabilidad:</strong> En la máxima medida permitida por la ley, nuestra responsabilidad total en relación con tu uso de SombreroWallet no excederá el monto total de las tarifas pagadas por ti a SombreroWallet en los seis meses anteriores a la reclamación.</li>
                            </ul>
                            
                            <h2>7. Privacidad y Protección de Datos</h2>
                            <p>Recopilamos, usamos y protegemos tus datos personales de acuerdo con nuestra Política de Privacidad. Te recomendamos leer nuestra Política de Privacidad para entender cómo manejamos tu información.</p>
                            
                            <h2>8. Modificaciones</h2>
                            <p>SombreroWallet se reserva el derecho de modificar estos Términos en cualquier momento. Las modificaciones entrarán en vigor cuando se publiquen en la Wallet. Te notificaremos sobre cambios significativos.</p>
                            
                            <h2>9. Ley Aplicable y Jurisdicción</h2>
                            <p>Estos Términos se rigen por las leyes de la República de Colombia. Cualquier disputa relacionada con estos Términos se resolverá en los tribunales competentes de Bogotá, Colombia.</p>
                            
                            <h2>10. Contacto</h2>
                            <p>Si tienes preguntas o inquietudes sobre estos Términos, por favor contáctanos a través de:</p>
                            <ul>
                                <li><strong>Correo electrónico:</strong> <a href="mailto:correo@sombrerowallet.com">correo@sombrerowallet.com</a></li>
                                <li><strong>Dirección:</strong> [Dirección física de la empresa]</li>
                                <li><strong>Teléfono:</strong> [Número de teléfono]</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Terms Modal -->
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Register",
  components: {},
  data() {
    return {
      form: {
        username: "",
        password: "",
        password2: "",
      },
      showError: false,
      messageError: "",
    };
  },
  methods: {
    ...mapActions(["Register"]),
    async submit() {
      try {
        this.showError = false
        this.messageError = null
        await this.Register(this.form);
        console.log('despues de registrar')
        this.showError = false
        alert("Registro existoso, ahora puedes ingresar")
        this.$router.push("/login");
      } catch (error) {
        console.log("error: ", error)
        this.showError = true
        this.messageError = error
      }
    },
  },
};
</script>
