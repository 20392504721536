<template>
    
    <!-- App Header -->
    <div class="appHeader no-border">
        <div class="left">
            <a href="/" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            Splash Page Image
        </div>
        <div class="right">
        </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule">

        <div class="section">
            <div class="splash-page mt-5 mb-5">
                <div class="mb-3"><img src="@/assets/img/sample/photo/vector1.png" alt="image" class="imaged w-50 square">
                </div>
                <h2 class="mb-2">¡Bienvenidos de nuevo!</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam aliquam.
                </p>
            </div>
        </div>

        <div class="fixed-bar">
            <div class="row">
                <div class="col-6">
                    <a href="/" class="btn btn-lg btn-outline-secondary btn-block">Cancela</a>
                </div>
                <div class="col-6">
                    <a href="#" class="btn btn-lg btn-primary btn-block">Ingresar</a>
                </div>
            </div>
        </div>

    </div>
    <!-- * App Capsule -->

</template>