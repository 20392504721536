<template>

    <!-- App Header -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            Blog Post
        </div>
        <div class="right">
            <a href="#" class="headerButton" data-bs-toggle="modal" data-bs-target="#actionSheetShare">
                <ion-icon name="share-social-outline"></ion-icon>
            </a>
        </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule">


        <div class="section mt-2">
            <h1>
                Rules you need to know in business
            </h1>
            <div class="blog-header-info mt-2 mb-2">
                <div>
                    <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="img" class="imaged w24 rounded me-05">
                    by <a href="#">Jack Doe</a>
                </div>
                <div>
                    24, September 2021
                </div>
            </div>
            <div class="lead">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam aliquam fringilla euismod. Nulla viverra
                eu ante tincidunt viverra. Sed dignissim maximus turpis et dictum.
            </div>
        </div>

        <div class="section mt-2">
            <p>
                Proin luctus viverra volutpat. Aenean hendrerit nisi quis consequat pretium. Maecenas ut vestibulum
                justo. Morbi eleifend ante eget arcu sodales malesuada. Nunc interdum ac diam ut bibendum. Proin gravida
                sit amet urna ac scelerisque. Vivamus consectetur ex vel felis bibendum fermentum.
            </p>
            <figure>
                <img src="@/assets/img/sample/photo/3.jpg" alt="image" class="imaged img-fluid">
            </figure>
            <p>
                Nullam augue magna, dignissim sit amet libero eu, ultrices tempus metus. Ut finibus ac justo eu tempor.
                Quisque egestas lectus neque, quis sodales lacus volutpat id.
            </p>
            <h3>Quisque id risus diam</h3>
            <p>
                Vivamus venenatis at purus at varius. Nam pharetra, magna et interdum dignissim, purus risus ullamcorper
                ipsum, et pharetra turpis ex vel orci.
            </p>
            <figure>
                <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged img-fluid">
            </figure>
            <h3>Pellentesque dictum</h3>
            <p>
                Pellentesque condimentum ornare nibh, nec iaculis purus faucibus ac. Etiam lacus ante, eleifend et
                aliquam a, tristique vel urna.
            </p>
            <p>
                Vivamus venenatis at purus at varius. Nam pharetra, magna et interdum dignissim, purus risus ullamcorper
                ipsum, et pharetra turpis ex vel orci. Nulla tincidunt nibh ac elit semper placerat. Fusce mattis,
                sapien vel vulputate scelerisque, ligula erat mollis elit, vitae condimentum ante leo quis quam. Vivamus
                sit amet quam ut eros varius venenatis et et orci. Pellentesque dictum egestas odio, sed auctor nulla
                euismod quis. Donec elementum feugiat ex, nec pharetra nulla sodales ac.
            </p>
        </div>

        <div class="section">
            <a href="#" class="btn btn-block btn-primary" data-bs-toggle="modal" data-bs-target="#actionSheetShare">
                <ion-icon name="share-outline"></ion-icon> Share This Post
            </a>
        </div>


        <div class="section mt-3">
            <h2>Related Posts</h2>
            <div class="row mt-3">
                <div class="col-6 mb-2">
                    <a href="app-blog-post.html">
                        <div class="blog-card">
                            <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w-100">
                            <div class="text">
                                <h4 class="title">What will be the value of bitcoin in the next...</h4>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-6 mb-2">
                    <a href="app-blog-post.html">
                        <div class="blog-card">
                            <img src="@/assets/img/sample/photo/2.jpg" alt="image" class="imaged w-100">
                            <div class="text">
                                <h4 class="title">Rules you need to know in business</h4>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-6 mb-2">
                    <a href="app-blog-post.html">
                        <div class="blog-card">
                            <img src="@/assets/img/sample/photo/3.jpg" alt="image" class="imaged w-100">
                            <div class="text">
                                <h4 class="title">10 easy ways to save your money</h4>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-6 mb-2">
                    <a href="app-blog-post.html">
                        <div class="blog-card">
                            <img src="@/assets/img/sample/photo/4.jpg" alt="image" class="imaged w-100">
                            <div class="text">
                                <h4 class="title">Follow the financial agenda with...</h4>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>


    </div>
    <!-- * App Capsule -->

    <!-- Share Action Sheet -->
    <div class="modal fade action-sheet inset" id="actionSheetShare" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Share with</h5>
                </div>
                <div class="modal-body">
                    <ul class="action-button-list">
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                <span>
                                    <ion-icon name="logo-facebook"></ion-icon>
                                    Facebook
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                <span>
                                    <ion-icon name="logo-twitter"></ion-icon>
                                    Twitter
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                <span>
                                    <ion-icon name="logo-instagram"></ion-icon>
                                    Instagram
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                <span>
                                    <ion-icon name="logo-linkedin"></ion-icon>
                                    Linkedin
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- * Share Action Sheet -->


    <!-- App Bottom Menu -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Overview</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Pages</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Components</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>My Cards</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Settings</strong>
            </div>
        </a>
    </div>
    <!-- * App Bottom Menu -->

</template>