<template>

    <!-- App Header -->
    <div class="appHeader">
        <div class="left">
            
            <router-link v-bind:to="{ name: 'app-me' }" class="headerButton">
                <div class="in">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </div>
            </router-link>
        </div>
        <div class="pageTitle">Verificacion KYC</div>
        <div class="right">
        </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule">

        <form @submit.prevent="submit">
            <div class="section p-2">
                <div class="section-title">Datos del perfil</div>
                <div class="card">
                    <div class="card-body">
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid1">Nombre completo</label>
                                <input type="text" class="form-control" id="userid1" placeholder="Cargando datos..."
                                    v-bind:value="currentInfo.first_name + ' ' + currentInfo.second_name + ' ' + currentInfo.surname">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="amount1">Tipo de documento</label>
                                        <input type="text" class="form-control" id="amount1" placeholder="Cargando datos..." v-bind:value="currentInfo.document_type.name">
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="amount1">Numero de documento</label>
                                        <input type="text" class="form-control" id="amount1" placeholder="Cargando datos..." v-bind:value="currentInfo.document_number">
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-button-group transparent">
                    <button type="submit" class="btn btn-primary btn-block btn-lg">Enviar para su verificacion</button>
                </div>
            </div>
            
            <div class="section mt-2">
                <div class="section-title">Direccion</div>
                <div class="card">
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="amount1">Pais</label>
                                            <input type="text" class="form-control" id="amount1" placeholder="Cargando datos..." v-bind:value="currentInfo.country.name">
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="amount1">Ciudad</label>
                                            <input type="text" class="form-control" id="amount1" placeholder="Cargando datos..." v-bind:value="currentInfo.city.name">
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="userid1">Direccion completa</label>
                                    <input type="text" class="form-control" id="userid1" placeholder="Cargando datos..." v-bind:value="currentInfo.address + ' ' + currentInfo.address_extra">
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
            <div class="section mt-2">
                <div class="section-title">Informacion financiera</div>
                <div class="card">
                    <div class="card-body">
                        <form>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="select4">Fuente de ingresos</label>
                                    <select class="form-control custom-select">
                                        <option value="co">Otros</option>
                                    </select>
                                </div>
                                <div class="input-info">Como obtienes tu dinero</div>
                            </div>

                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="select4">Ocupacion</label>
                                    <select class="form-control custom-select">
                                        <option value="co">Otros</option>
                                    </select>
                                </div>
                                <div class="input-info">Esto es para conocerte mejor</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </form>










        <br />
                
                <div class="form-button-group transparent">
                    <button type="submit" class="btn btn-primary btn-block btn-lg">Comenzar Verificacion</button>
                </div>

    </div>
    <!-- * App Capsule -->

</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
    name: 'MeverifyKYC',
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({ 'currentInfo': 'StateUser' }),
    },
    mounted() {
        let self = this;
        this.$root.runBase()
        if (this.currentInfo) {
            // this.form.email = this.currentInfo.email
        }
    },
}
</script>

