<template>

    <!-- App Bottom Menu -->
    <div class="appBottomMenu">
        <a href="/" class="item active">
            <div class="col">
                <ion-icon name="home-outline"></ion-icon>
                <strong>Vista General</strong>
            </div>
        </a>
        <a href="/crypto" class="item">
            <div class="col">
                <ion-icon name="pulse-outline"></ion-icon>
                <strong>Mis activos</strong>
            </div>
        </a>
        <a href="/deposit" class="item">
            <div class="col">
                <div class="action-button large">
                    <ion-icon name="logo-usd"></ion-icon>
                </div>
            </div>
        </a>
        <!-- <a href="#" class="item">
            <div class="col">
                <div class="action-button large">
                    <ion-icon name="logo-bitcoin"></ion-icon>
                </div>
            </div>
        </a>
        <a href="#" class="item">
            <div class="col">
                <div class="action-button large">
                    <ion-icon name="swap-vertical"></ion-icon>
                </div>
            </div>
        </a> -->
        <a href="#" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis tarjetas</strong>
            </div>
        </a>
        <a href="#" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Ajustes</strong>
            </div>
        </a>
    </div>
    <!-- * App Bottom Menu -->
        
    <!-- App Bottom Menu -->
    <!-- <div class="appBottomMenu">
        <a href="index.html" class="item active">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Overview</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Pages</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Components</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>My Cards</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Settings</strong>
            </div>
        </a>
    </div> -->
    <!-- * App Bottom Menu -->

</template>

<script>
export default {
  name: 'AppBottomMenu',
//   props: {
//     msg: String
//   },
  mounted() {
  },
}
</script>
