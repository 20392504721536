<template>
    <div class="section full mt-4">
        <div class="section-heading padding">
            <h2 class="title">Enviar saldo</h2>
            <a href="#" class="link">Crear nuevo</a>
        </div>
        <!-- carousel small -->
        <div class="carousel-small splide">
            <div class="splide__track">
                <ul class="splide__list">
                    <li class="splide__slide">
                        <a href="#">
                            <div class="user-card">
                                <img src="@/assets/img/sample/avatar/avatar2.jpg" alt="img" class="imaged w-100">
                                <strong>Jurrien</strong>
                            </div>
                        </a>
                    </li>
                    <li class="splide__slide">
                        <a href="#">
                            <div class="user-card">
                                <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="img" class="imaged w-100">
                                <strong>Elwin</strong>
                            </div>
                        </a>
                    </li>
                    <li class="splide__slide">
                        <a href="#">
                            <div class="user-card">
                                <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="img" class="imaged w-100">
                                <strong>Alma</strong>
                            </div>
                        </a>
                    </li>
                    <li class="splide__slide">
                        <a href="#">
                            <div class="user-card">
                                <img src="@/assets/img/sample/avatar/avatar5.jpg" alt="img" class="imaged w-100">
                                <strong>Justine</strong>
                            </div>
                        </a>
                    </li>
                    <li class="splide__slide">
                        <a href="#">
                            <div class="user-card">
                                <img src="@/assets/img/sample/avatar/avatar6.jpg" alt="img" class="imaged w-100">
                                <strong>Maria</strong>
                            </div>
                        </a>
                    </li>
                    <li class="splide__slide">
                        <a href="#">
                            <div class="user-card">
                                <img src="@/assets/img/sample/avatar/avatar7.jpg" alt="img" class="imaged w-100">
                                <strong>Pamela</strong>
                            </div>
                        </a>
                    </li>
                    <li class="splide__slide">
                        <a href="#">
                            <div class="user-card">
                                <img src="@/assets/img/sample/avatar/avatar8.jpg" alt="img" class="imaged w-100">
                                <strong>Neville</strong>
                            </div>
                        </a>
                    </li>
                    <li class="splide__slide">
                        <a href="#">
                            <div class="user-card">
                                <img src="@/assets/img/sample/avatar/avatar9.jpg" alt="img" class="imaged w-100">
                                <strong>Alex</strong>
                            </div>
                        </a>
                    </li>
                    <li class="splide__slide">
                        <a href="#">
                            <div class="user-card">
                                <img src="@/assets/img/sample/avatar/avatar10.jpg" alt="img" class="imaged w-100">
                                <strong>Stina</strong>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- * carousel small -->
    </div>
</template>

<script>
export default {
    name: 'SectionSendMoney',
    mounted() {
        
            // Small Carousel
            document.querySelectorAll('.carousel-small').forEach(carousel => new Splide(carousel, {
                perPage: 9,
                rewind: false,
                type: "loop",
                gap: 16,
                padding: 16,
                arrows: false,
                pagination: false,
                breakpoints: {
                    768: {
                        perPage: 4
                    },
                    991: {
                        perPage: 7
                    }
                }
            }).mount());
    },
}
</script>