<template>
    <AppHeader />
    <!-- App Capsule -->
    <div id="appCapsule">
        <SectionWalletFull />
        <!-- <SectionStats /> -->
        <SectionPortfolio />
        <SectionWatchlist />
        <CryptoSectionTransactions />
        <SectionPromo />
        <!-- <SectionLastestNews /> -->
         <br />
        <AppFooter />
    </div>
    <!-- * App Capsule -->
     <!-- <AppBottomMenu /> -->
     <AppSidebar />
</template>

<script>
import ApexCharts from 'apexcharts'
export default {
    name: 'HomeView',
    created() {
    },
    mounted() {
        this.$root.runBase()
        
    },
    methods: {
    },
}
</script>