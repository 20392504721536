import axios from 'axios';
import store from '@/store'
import { compileAsync } from 'sass'

import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import ForgotPasswordView from '../views/ForgotPasswordView.vue'
import RegisterView from '../views/RegisterView.vue'
import ChangePasswordView from '../views/ChangePasswordView.vue'
import TransactionVerificationView from '../views/TransactionVerificationView.vue'
import TransactionDetailView from '../views/TransactionDetailView.vue'
import TransactionsView from '../views/TransactionsView.vue'
import DepositView from '../views/DepositView.vue'
import DepositSelectGatewayView from '../views/DepositSelectGatewayView.vue'
import DepositGatewayView from '../views/DepositGatewayView.vue'
import SendView from '../views/SendView.vue'
import SendSelectGatewayView from '../views/SendSelectGatewayView.vue'
import SendGatewayView from '../views/SendGatewayView.vue'
import CryptoExchangeView from '../views/CryptoExchangeView.vue'
import ExchangeExecView from '../views/ExchangeExecView.vue'
import MyCardsView from '../views/MyCardsView.vue'
import CryptoHomeView from '../views/CryptoHomeView.vue'
import CryptoPortfolioView from '../views/CryptoPortfolioView.vue'
import CryptoCoinDetailView from '../views/CryptoCoinDetailView.vue'
import CryptoTransactionsView from '../views/CryptoTransactionsView.vue'
import CryptoTransactionDetailView from '../views/CryptoTransactionDetailView.vue'
import MeSettingsView from '../views/MeSettingsView.vue'
import MeChangePasswordView from '../views/MeChangePasswordView.vue'
import MeVerifyKYC from '../views/MeVerifyKYC.vue'
import NotificationsView from '../views/NotificationsView.vue'
import NotificationDetailView from '../views/NotificationDetailView.vue'
import SupportView from '../views/SupportView.vue'
import WithdrawView from '../views/WithdrawView.vue'
import PagesView from '../views/PagesView.vue'
import SavingsView from '../views/SavingsView.vue'
import BillsView from '../views/BillsView.vue'
import SmsVerificationView from '../views/SmsVerificationView.vue'
import QrCodeView from '../views/QrCodeView.vue'
import BlogView from '../views/BlogView.vue'
import BlogPostView from '../views/BlogPostView.vue'
import AboutView from '../views/AboutView.vue'
import ContactView from '../views/ContactView.vue'
import FaqView from '../views/FaqView.vue'
import Error404View from '../views/Error404View.vue'
import SplashView from '../views/SplashView.vue'
import SplashImageView from '../views/SplashImageView.vue'

// Ususario
import MeView from '../views/MeView.vue'
import MeHomeView from '../views/MeHomeView.vue'

import MeProfileEditView from '../views/MeProfileEditView.vue'
import MeProfileChangePasswordView from '../views/MeProfileChangePasswordView.vue'
import MeAddressEditView from '../views/MeAddressEditView.vue'
import MeMobileEditView from '../views/MeMobileEditView.vue'
import MeAccountEditView from '../views/MeAccountEditView.vue'

import MeProfileVerifyView from '../views/MeProfileVerifyView.vue'
import MeAddressVerifyView from '../views/MeAddressVerifyView.vue'
import MeVerificationMobileView from '../views/MeVerificationMobileView.vue'

import CalcActionView from '../views/CalcActionView.vue'

const routes = [
  {
    path: '/login',
    name: 'app-login',
    component: LoginView,
    meta: { guest: true },
  },
  {
    path: '/forgot-password',
    name: 'app-forgot-password',
    component: ForgotPasswordView,
    meta: { guest: true },
  },
  {
    path: '/register',
    name: 'app-register',
    component: RegisterView,
    meta: { guest: true },
  },

  {
    path: '/',
    name: 'app-home',
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/notifications',
    name: 'app-notifications',
    component: NotificationsView,
    meta: { requiresAuth: true }
  },

  {
    path: '/me',
    // name: 'app-me',
    component: MeView,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'app-me',
        component: MeHomeView,
        meta: { requiresAuth: true }
      },
      {
        path: 'settings',
        name: 'app-me-settings',
        component: MeSettingsView,
        meta: { requiresAuth: true }
      },
      {
        path: 'profile-edit',
        name: 'me-profile-edit',
        component: MeProfileEditView,
        meta: { requiresAuth: true }
      },
      {
        path: 'profile-verify',
        name: 'me-profile-verify',
        component: MeProfileVerifyView,
        meta: { requiresAuth: true }
      },
      {
        path: 'profile-change-password',
        name: 'me-profile-change-password',
        component: MeProfileChangePasswordView,
        meta: { requiresAuth: true }
      },
      {
        path: 'account-edit',
        name: 'me-account-edit',
        component: MeAccountEditView,
        meta: { requiresAuth: true }
      },
      {
        path: 'address-edit',
        name: 'me-address-edit',
        component: MeAddressEditView,
        meta: { requiresAuth: true }
      },
      {
        path: 'address-verify',
        name: 'me-address-verify',
        component: MeAddressVerifyView,
        meta: { requiresAuth: true }
      },
      {
        path: 'mobile-edit',
        name: 'me-mobile-edit',
        component: MeMobileEditView,
        meta: { requiresAuth: true }
      },
      {
        path: 'verification/mobile',
        name: 'me-verification-mobile',
        component: MeVerificationMobileView,
        meta: { requiresAuth: true }
      },
      {
        path: 'verification/kyc',
        name: 'me-verification-kyc',
        component: MeVerifyKYC,
        meta: { requiresAuth: true }
      },
    ]
  },

  {
    path: '/calc/:action',
    name: 'app-calc-action',
    component: CalcActionView,
    meta: { requiresAuth: true }
  },

  {
    path: '/verify-transaction/:type/:from_id/:to_id/:gateway_id',
    name: 'app-verify-transaction',
    component: CalcActionView,
    meta: { requiresAuth: true }
  },

  {
    path: '/sms-verification',
    name: 'app-sms-verification',
    component: SmsVerificationView,
    meta: { requiresAuth: true }
  },
  {
    path: '/transaction-verification/:tx_id',
    name: 'app-transaction-verification',
    component: TransactionVerificationView,
    meta: { requiresAuth: true }
  },
  {
    path: '/transaction-detail/:tx_id',
    name: 'app-transaction-detail',
    component: TransactionDetailView,
    meta: { requiresAuth: true }
  },
  {
    path: '/transactions',
    name: 'app-transactions',
    component: TransactionsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/deposit',
    name: 'app-deposit',
    component: DepositView,
    meta: { requiresAuth: true }
  },
  {
    path: '/deposit-select-gateway',
    name: 'app-deposit-select-gateway',
    component: DepositSelectGatewayView,
    meta: { requiresAuth: true }
  },
  {
    path: '/deposit-gateway/:gateway_slug',
    name: 'app-deposit-gateway',
    component: DepositGatewayView,
    meta: { requiresAuth: true }
  },
  {
    path: '/send',
    name: 'app-send',
    component: SendView,
    meta: { requiresAuth: true }
  },
  {
    path: '/send-select-gateway',
    name: 'app-send-select-gateway',
    component: SendSelectGatewayView,
    meta: { requiresAuth: true }
  },
  {
    path: '/send-gateway/:gateway_slug',
    name: 'app-send-gateway',
    component: SendGatewayView,
    meta: { requiresAuth: true }
  },
  {
    path: '/crypto/exchange',
    name: 'crypto/exchange',
    component: CryptoExchangeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/exchange-exec',
    name: 'app-exchange-exec',
    component: ExchangeExecView,
    meta: { requiresAuth: true }
  },
  {
    path: '/crypto/transactions',
    name: 'crypto-transactions',
    component: CryptoTransactionsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/crypto/transaction-detail/:tx_id',
    name: 'crypto-transaction-detail',
    component: CryptoTransactionDetailView,
    meta: { requiresAuth: true }
  },
  {
    path: '/crypto',
    name: 'crypto-home',
    component: CryptoHomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/crypto/portfolio',
    name: 'crypto-portfolio',
    component: CryptoPortfolioView,
    meta: { requiresAuth: true }
  },
  {
    path: '/crypto/coin-detail/:coin_id',
    name: 'crypto-coin-detail',
    component: CryptoCoinDetailView,
    meta: { requiresAuth: true }
  },
  {
    path: '/my-cards',
    name: 'app-my-cards',
    component: MyCardsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/notification-detail/:notification_id',
    name: 'app-notification-detail',
    component: NotificationDetailView,
    meta: { requiresAuth: true }
  },
  {
    path: '/support',
    name: 'app-support',
    component: SupportView,
    meta: { requiresAuth: true }
  },
  {
    path: '/withdraw',
    name: 'app-withdraw',
    component: WithdrawView,
    meta: { requiresAuth: true }
  },
  {
    path: '/pages',
    name: 'app-pages',
    component: PagesView,
    meta: { requiresAuth: true }
  },
  {
    path: '/savings',
    name: 'app-savings',
    component: SavingsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/bills',
    name: 'app-bills',
    component: BillsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/qr-code',
    name: 'app-qr-code',
    component: QrCodeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/blog',
    name: 'app-blog',
    component: BlogView,
    meta: { requiresAuth: true }
  },
  {
    path: '/blog-post/:blog_id',
    name: 'app-blog-post',
    component: BlogPostView,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'app-about',
    component: AboutView,
    // meta: { requiresAuth: true }
  },
  {
    path: '/contact',
    name: 'app-contact',
    component: ContactView,
    // meta: { requiresAuth: true }
  },
  {
    path: '/faq',
    name: 'app-faq',
    component: FaqView,
    // meta: { requiresAuth: true }
  },
  {
    path: '/404',
    name: 'app-error-404',
    component: Error404View,
    // meta: { requiresAuth: true }
  },
  {
    path: '/splash',
    name: 'app-splash',
    component: SplashView,
    // meta: { requiresAuth: true }
  },
  {
    path: '/splash-image',
    name: 'app-splash-image',
    component: SplashImageView,
    // meta: { requiresAuth: true }
  },
]

console.log('Creando Router')
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})


// router.beforeEach(async (to, from, next) => {
//   console.log('clear::aqui')
//   let sStatus = await store.getters.sessionState
//   console.log('sStatus', sStatus)
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     console.log('req auth')
//     if (store.getters.sessionState == 'connected') {
//       next()
//       return
//     }
//     next('/login')
//   } 
//   else if (to.matched.some((record) => record.meta.guest)) {
//     console.log('req guest')
//     if (store.getters.sessionState == 'connected') {
//       next("/about");
//       return;
//     }
//     next();
//   } 
//   else {
//     next();
//   }
// })

export default router
