<template>

    <!-- App Header -->
    <div class="appHeader no-border transparent position-absolute">
        <div class="left">
            <a href="/login" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle"></div>
        <div class="right">
        </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule">

        <div class="section mt-2 text-center">
            <h1>Has olvidado tu contraseña</h1>
            <h4>Escribe tu correo electrónico para restablecer tu contraseña</h4>
        </div>
        <div class="section mb-5 p-2">
            <form action="/login">
                <div class="card">
                    <div class="card-body pb-1">

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="email1">Correo electrónico</label>
                                <input type="email" class="form-control" id="email1" placeholder="Correo electrónico">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-button-group transparent">
                    <button type="submit" class="btn btn-primary btn-block btn-lg">Restablecer contraseña</button>
                </div>

            </form>
        </div>

    </div>
    <!-- * App Capsule -->
</template>