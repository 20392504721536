<template>

    <!-- App Header -->
    <div class="appHeader">
        <div class="left">
            <a href="/" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            Seleccione red de origen
        </div>
        <div class="right">
            <!-- <a href="crypto-exchange.html" class="headerButton">
                <ion-icon name="add"></ion-icon>
            </a> -->
        </div>
    </div>
    <!-- * App Header -->


    <!-- App Capsule -->
    <div id="appCapsule">
        
        <!-- Wallet Card -->
        <div class="section pt-1">
            <div class="wallet-card">
                <!-- Balance -->
                <div class="balance">
                    <div class="left">
                        <span class="title">Valor a recargar</span>
                        <h1 class="total">$ 2,562.50 COP</h1>
                    </div>
                    <!-- <div class="right">
                        <a href="#" class="button" data-bs-toggle="modal" data-bs-target="#depositActionSheet">
                            <ion-icon name="add-outline"></ion-icon>
                        </a>
                    </div> -->
                </div>
                <!-- * Balance -->
                <!-- Wallet Footer -->
                <div class="wallet-footer">
                    <div class="item">
                        <a href="/deposit-gateway/zzzzzzzzz">
                            <div class="icon-wrapper bg-danger">
                                <ion-icon name="arrow-down-outline"></ion-icon>
                            </div>
                            <strong>Wompi</strong>
                        </a>
                    </div>
                    <div class="item">
                        <a href="deposit-gateway/xxxxxxxxxx">
                            <div class="icon-wrapper">
                                <ion-icon name="arrow-forward-outline"></ion-icon>
                            </div>
                            <strong>Binance Smart Chain</strong>
                        </a>
                    </div>
                    <!-- <div class="item">
                        <a href="#" data-bs-toggle="modal" data-bs-target="#exchangeActionSheet">
                            <div class="icon-wrapper bg-warning">
                                <ion-icon name="swap-vertical"></ion-icon>
                            </div>
                            <strong>Exchange</strong>
                        </a>
                    </div> -->

                </div>
                <!-- * Wallet Footer -->
            </div>
        </div>
        <!-- Wallet Card -->

        <!-- <div class="section">
            <div class="row mt-2">
                <div class="col-6">
                    <div class="stat-box">
                        <div class="title">Wompi</div>
                        <div class="value text-success">Nequi - Bancolombia - Ahorro a la mano</div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="stat-box">
                        <div class="title">Red de pruebas Binance</div>
                        <div class="value text-danger">BNB,USDT</div>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
    <!-- * App Capsule -->

</template>